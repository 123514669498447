import React, { Component } from "react";
import { Alert, Col, Container, Row, Button } from "react-bootstrap";
import defaultLogo from "../asset/img/logo.jpg";
import ApiService from "../core/service/ApiService";
import { loc } from "../core/utils";
import ReactGA from 'react-ga';

class NetheosPage extends Component {
  componentDidMount() {
    const { tunnelData } = this.props;
    this.handleNetheosIFrameOpening(tunnelData);
      if (process.env.NODE_ENV === "production") {
        console.log("Init GA tag for production");
        ReactGA.initialize('UA-76715818-5', {
          debug: true
        });
      } else {
        console.log("Init GA tag for QA");
        ReactGA.initialize('UA-181806712-1', {
          debug: true
        });
      }
      const newStep = this.findCurrentStepFromTunnelData(tunnelData);
      ReactGA.pageview(newStep.route);
  }

  render() {
    const { tunnelData } = this.props;
    console.log("TUNNEL DATA DANS LE RENDER DE NETHEOS", tunnelData);
    console.log("Néthéos render page : uuid =", tunnelData.netheosToken);
    return (
      <>
        {/* ————— TOPBAR ————— */}
        <header>
          <nav className="text-center">
            <Row className="justify-content-center no-gutters">
              <Col xs={5} md={3} lg={2}>
                <a href="https://www.uzit-direct.com/" title="UZ'it">
                  <img src={defaultLogo} alt="UZ'it" />
                </a>
              </Col>
            </Row>
          </nav>
        </header>
        <Row className="justify-content-center no-gutters">
          <Col sm={12}>
            <ul className="breadcrumb">
              <li>{loc`My addresses`}</li>
              <li>{loc`My credit card data`}</li>
              <li className="active">{loc`My contract`}</li>
              <li>{loc`Finalization`}</li>
            </ul>
          </Col>
        </Row>
        {/* ————— CONTENT ————— */}
        <Container>
          <Row>
            <Col sm={12}>
              <h1>{loc`My contract`}</h1>
              <Alert variant="primary">
                <p>
                {loc`Your product will be delivered to you after the final acceptance of the long-term rental contract and upon receipt of supporting documents by the rental company.`}
                </p>
              </Alert>
            </Col>
          </Row>
          <div id="iframe-wrapper"></div>
          <footer>
            <Row>
              <Col>
                <p>{loc`Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °433 911 799.`}</p>
                <p>{loc`Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125 71 - ORIAS number: 13003909.`}</p>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }

  findCurrentStepFromTunnelData(tunnelData) {
    const stepId = tunnelData.step;
    const stepIndex = tunnelData.breadcrumbs.findIndex(step => step.id === stepId);
    const currentStep = tunnelData.breadcrumbs[stepIndex];
    if (currentStep) {
      return currentStep;
    }
    throw "Error : no step with id " + stepId + " has been found in tunnelData";
  }

  handleNetheosIFrameOpening(tunnelData) {
  // should be returnUrl according to swagger documentation
    var tokenNetheos = tunnelData.netheosToken;
    var iframeSrc = tunnelData.netheosIFrameUrl + tokenNetheos;
    var textToRetrieve =
        '<iframe id="contractSignatureFrame" title="Documents Signature Frame" width="100%" height="2100" src='
        + iframeSrc
        + "></iframe>";
    document.getElementById("iframe-wrapper").innerHTML = textToRetrieve;
    this.initNetheosHandlers(tunnelData);
  }

  initNetheosHandlers(tunnelData) {
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
    var signatureApiBaseUrl = tunnelData.apiUrl + "middle-business-service/api/v2/order/" + tunnelData.opl + "/signature/" + tunnelData.netheosToken ;
    const { history } = this.props
    const token = tunnelData.access_token;
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "*/*",
      "Content-Type": "application/json"
    };

    var MESSAGE_TITLES = {
      EXIT: "A bientôt !",
      SUSPENDED: "Pièces justificatives en cours de contrôle",
      FINALIZED: "Dossier complet",
      WAITING: "Dossier en cours de validation",
      ACCEPTED: "Félicitations !",
      REJECTED: "Désolé"
    };

    var MESSAGE_TEXTS = {
      EXIT: "Vous pouvez revenir quand vous le souhaitez compléter votre dossier.",
      SUSPENDED:
          "Une ou plusieurs des pièces que vous avez soumises sont en cours d'examen par nos opérateurs. Merci de revenir plus tard.",
      FINALIZED: "Votre dossier est désormais complet, nous vous recontacterons quand nous l'aurons examiné.",
      WAITING: "Votre dossier est en cours d'examen.",
      ACCEPTED: "Votre dossier a été accepté.",
      REJECTED: "Votre dossier a été refusé.",
      SIGNATURE_REJECTED: "Votre dossier est annulé, la signature du contrat a été refusée.",
      TOO_MANY_OTP_TRIES: "Votre dossier est annulé, trop de code OTP erronés envoyés lors de la signature."
    };


    eventer(
        messageEvent,
        function(event) {
          var jsEvent = JSON.parse(event.data);
          if (jsEvent.type !== "displayEvent") {
            console.log("event received");
            console.log("Event / JS event : ", event);
            console.log("Event / JS event : ", jsEvent);
          }

          if (jsEvent.type === "endEvent") {
            console.warning("endEvent Detected ! ", jsEvent);
            console.log("Exiting the page");
          } else if (jsEvent.type === "userEvent") {
            // All user actions => used by web analytics libraries like Google Analytics
            console.log("user event : " + jsEvent.action);
            if (jsEvent.action === "CHECK_CLAUSE") {
              if (jsEvent.context.checked === true) {
                console.log("user event : check clause OK");
                ApiService.postForEvollisApi(
                    signatureApiBaseUrl + "/log-user-event/CHECK_CLAUSE_OK",
                    null,
                    { headers }
                );
              } else {
                console.log("user event : check clause KO");
                ApiService.postForEvollisApi(
                    signatureApiBaseUrl + "/log-user-event/CHECK_CLAUSE_KO",
                    null,
                    { headers }
                );
              }
            } else {
              console.log("user event other : ", jsEvent);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/log-user-event/" + jsEvent.action,
                  null,
                  { headers }
              );
            }
          } else if (jsEvent.type === "clientFileEvent") {
            // Event coming from eKeynox SaaS with data concerning the state of the client file
            console.log("client file state : " + jsEvent.state);

            if ((jsEvent.changedState
                && jsEvent.changedState.from === "PENDING"
                && (jsEvent.changedState.to === "WAITING" || jsEvent.changedState.to === "ACCEPTED"))
                || jsEvent.participantState === "WAITING") {
              console.log(MESSAGE_TITLES["FINALIZED"]);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/end",
                  null,
                  { headers }
              );
              console.log("final request has been sent to Evollis' API");
              history.push("/uzit/final");
            } else if (jsEvent.state === "PENDING" && jsEvent.context.type === "signatureRefusal") {
              console.log(MESSAGE_TEXTS["SIGNATURE_REJECTED"]);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/signatureRefusal",
                  null,
                  { headers }
              );
              history.push("/uzit/score-ko");
            } else if (jsEvent.state === "REJECTED" && jsEvent.cause === "TOO_MANY_OTP_TRIES") {
              console.log(MESSAGE_TEXTS["TOO_MANY_OTP_TRIES"]);
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/TOO_MANY_OTP_TRIES",
                  null,
                  { headers }
              );
              history.push("/uzit/score-ko");
            } else {
              if (jsEvent.state !== "PENDING") {
                console.log(MESSAGE_TEXTS[jsEvent.state]);
              }
            }
          } else if (jsEvent.type === "exitEvent") {
            console.log(MESSAGE_TEXTS["EXIT"]);
            ApiService.postForEvollisApi(
                signatureApiBaseUrl + "/contract/events/exit",
                null,
                { headers }
            );
            history.push("/uzit/signature-paused");
          } else if (jsEvent.type === "errorEvent") {
            if (jsEvent.cause !== "INVALID_STATE") {
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/INVALID_STATE",
                  null,
                  { headers }
              );
            } else {
              ApiService.postForEvollisApi(
                  signatureApiBaseUrl + "/contract/events/errors",
                  null,
                  { headers }
              );
            }
          }
        },
        false
    );
  }
}

export default NetheosPage;
