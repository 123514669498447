import { makeValidate } from "../../form/makeValidate";
import { loc, isValidIban } from "../../core/utils";
import moment from "moment";
import * as yup from "yup";
import {missEnumCode} from "../../core/service/BusinessLogicService";

// error messages
console.warn("defining constants");
export const requiredMessage = () => loc`Required`;
export const selectNotCorrectValue = () => loc`Please pick a correct value`;
const maximumCharactersMessage = (maximumValue) => maximumValue + " " + loc`maximum characters`;
const regexMessage = () => loc`Unauthorized characters`;
const phoneMessage = () =>
  loc`Phone number must start with 06 or 07 and contain 10 numbers`;
const postalCodeMessage = () => loc`Invalid postal code`;
const birthDateMinMessage = () => loc`Minimum age must be > 18 years old`;
const birthDateMaxessage = () => loc`Maximum age must be < 110 years old`;
const mustBePositiveMessage = () => loc`Must be positive`;
const minimumCharactersMessage = (minimalValue) => minimalValue + " " +loc`minimum characters`;
const childrenContainsMessage = () =>
  loc`number of children must be contains between 0 and 10`;
const invalidBirthDateMessage = () => loc`birthDate is not valid`;
const employmentDateBeforeBirthDateMessage = () =>
  loc`Employment date cannot be before birth date`;
const wrongDiffMessage = () =>
  loc`difference between employment date and birth date must be at least 16 years`;
const wrongDiffMessageBankSeniorty = () =>
    loc`difference between bank seniority date and birth date must be at least 16 years`;
const futureEmploymentDateMessage = () =>
  loc`Employment date cannot be in future`;
const futureResidenceDateMessage = () => loc`This date cannot be in future`;
const residenceDateBeforeBirthDateMessage = () =>
  loc`Residence date cannot be before birth date`;
const yearSeniorityBeforeBirthDateMessage = () =>
    loc`Year seniority bank cannot be before birth date`;
const numberMessage = () => loc`Must be a number`;
const numberIntegerMessage = () => loc`Must be a whole number`;
const emailNotValidMessage = () => loc`Email invalid`;
const dateFormatValidationMessage = () => loc`Not a valid YYYY-MM-DD date`;
const dateYYYYFormatValidationMessage = () => loc`Not a valid YYYY date`;
const ibanWrongFormatMessage = () => loc`Not a valid IBAN`;
const bicWrongFormatMessage = () => loc`Not a valid BIC`;


// regex
const defaultRegex = /^[a-zA-ZêëéèçÊËÉÈÇàâäÀÂÄùûüÙÛÜìïîÌÏÎòôöÒÔÖ]+(([ -][a-zA-Z êëéèçÊËÉÈÇàâäÀÂÄùûüÙÛÜìïîÌÏÎòôöÒÔÖ])?[a-zA-ZêëéèçÊËÉÈÇàâäÀÂÄùûüÙÛÜìïîÌÏÎòôöÒÔÖ]*)*$/;
const postalCodeRegex = /^[0-9]{5}$/;
const YYYYMMDDDateFormatRegex = /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/;
const phoneRegex = /^[0]{1}[6-7]{1}[0-9]{8}$/;
// A choice has been made here : code reusability or performance : if we compose the regex dynamically perfs are affected.
// If perf problems are detected for validation, change this implementation
const minimumCharacterRegex = (minValue) => "^.{"+ minValue +",}$";
const maximumCaracterRegex = (maxValue) => "^.{0,"+ maxValue + "}$";
const ibanRegex = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}[a-zA-Z0-9]{12}/;
const bicRegex11Char = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}[A-Za-z0-9]{3}$/;
const bicRegex8Char = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}$/;

// todo : refactor in helpers redundant test

let schema = yup.object().shape({
    person: yup.object().shape({
      title: yup.string(),
      name : yup.string().test({
        name : "nameTest",
        message : "",
        test(value){
          if (!value) {
            return this.createError({
              message: requiredMessage,
              path: "person.name",
            });
          }
          if (!value.match(defaultRegex)) {
            return this.createError({
              message: regexMessage,
              path: "person.name",
            });
          }
          if (!value.match(minimumCharacterRegex(2))) {
            return this.createError({
              message: minimumCharactersMessage(2),
              path: "person.name",
            });
          }
          if (!value.match(maximumCaracterRegex(32))) {
            return this.createError({
              message: maximumCharactersMessage(32),
              path: "person.name",
            });
          }
        }
      }),
      maidenName : yup
      .string()
      .when("title", {
        is: missEnumCode,
        then: yup.string().nullable().test({
          name : "maidenNameTest",
          message : "",
          test(value){
            if (!value && this.parent["title"] === missEnumCode) {
              return this.createError({
                message: requiredMessage,
                path: "person.maidenName",
              });
            }
            if (!value.match(defaultRegex)) {
              return this.createError({
                message: regexMessage,
                path: "person.maidenName",
              });
            }
            if (!value.match(minimumCharacterRegex(2))) {
              return this.createError({
                message: minimumCharactersMessage(2),
                path: "person.maidenName",
              });
            }
            if (!value.match(maximumCaracterRegex(32))) {
              return this.createError({
                message: maximumCharactersMessage(32),
                path: "person.maidenName",
              });
            }
          }
        }),
        otherwise : yup.string().nullable(),
      }),
      firstName : yup.string().test({
        name : "firstNameTest",
        message : "",
        test(value){
          if (!value) {
            return this.createError({
              message: requiredMessage,
              path: "person.firstName",
            });
          }
          if (!value.match(defaultRegex)) {
            return this.createError({
              message: regexMessage,
              path: "person.firstName",
            });
          }
          if (!value.match(minimumCharacterRegex(2))) {
            return this.createError({
              message: minimumCharactersMessage(2),
              path: "person.firstName",
            });
          }
          if (!value.match(maximumCaracterRegex(25))) {
            return this.createError({
              message: maximumCharactersMessage(25),
              path: "person.firstName",
            });
          }
        }
      }),
      birthDate: yup
      .string().nullable()
      .test({
        name :"ageTest",
        message : "",
        test(value){
          if (!value) {
            return this.createError({message : requiredMessage, path : "person.birthDate"});
          }
          const birthDate = moment(value);
          // in some case, moment's library return jsDates and the regex is needed, but let it last for perfs reasons
          if (!birthDate.isValid() || (birthDate.isValid() && (birthDate.year() < 1000 || birthDate.year() > 9999)) || !value.match(YYYYMMDDDateFormatRegex)) {
              return this.createError({
              message: dateYYYYFormatValidationMessage,
              path: "person.birthDate",
            });
          }
          let nowDate = moment();
          if (!(nowDate.diff(birthDate, "years") >= 18)) {
            return this.createError({message : birthDateMinMessage, path : "person.birthDate"});
          }
          if (!(nowDate.diff(birthDate, "years") < 110)) {
            return this.createError({message : birthDateMaxessage, path : "person.birthDate"});
          }
        }
      }),
      birthCountryCode: yup.string().nullable().required(requiredMessage),
      birthCity: yup.string().nullable()
      .test({
        name :"birthCityTest",
        message : "",
        test(value){
          if (!value) {
            return this.createError({message : selectNotCorrectValue, path : "person.birthCity"});
          }
          return true;
        }
      }),
      birthDepartmentCode: yup.string().nullable().required(requiredMessage),
      citizenshipCountryCode: yup.string().nullable().required(requiredMessage),
    }),
    customer: yup.object().shape({
      bankAccountDTO: yup.object().shape({
        iban: yup
        .string().nullable()
        .test({
          name: "bic format verification",
          message: "",
          test(value) {
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.bankAccountDTO.iban",
              });
            }
            if (value.length > 27) {
              return this.createError({
                message: maximumCharactersMessage(27),
                path: "customer.bankAccountDTO.iban",
              });
            }
            if (!isValidIban(value)) {
              return this.createError({
                message: ibanWrongFormatMessage,
                path: "customer.bankAccountDTO.iban",
              });
            }
            if (!ibanRegex.test(value)) {
              return this.createError({
                message: ibanWrongFormatMessage,
                path: "customer.bankAccountDTO.iban",
              });
            }
            return true;
          }
        }),
        bic: yup
        .string()
        .nullable()
        .test({
          name: "bic format verification",
          message: "",
          test(value) {
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.bankAccountDTO.bic",
              });
            }
            if (value.length > 11) {
              return this.createError({
                message: maximumCharactersMessage(11),
                path: "customer.bankAccountDTO.bic",
              });
            }
            if (!bicRegex8Char.test(value) && !bicRegex11Char.test(value)) {
              return this.createError({
                message: bicWrongFormatMessage,
                path: "customer.bankAccountDTO.bic",
              });
            }
            return true;
          }
        }),
      }),
      maritalStatus: yup.string().required(requiredMessage),
      occupationCode: yup.string().nullable().required(requiredMessage),
      yearBankSeniority : yup.string().nullable()
      .test({
        name: "yearbankseniority verification",
        message: "",
        test(value) {
          // value is required
          if (!value) {
            return this.createError({
              message: requiredMessage,
              path: "customer.yearBankSeniority",
            });
          }

          const residenceDate = moment(value);
          // in some case, moment's library return jsDates and the regex is needed, but let it last for perfs reasons
          if (!residenceDate.isValid() || (residenceDate.isValid() && (residenceDate.year() < 1000 || residenceDate.year() > 9999)) || !value.match(YYYYMMDDDateFormatRegex)) {
            return this.createError({
              message: dateYYYYFormatValidationMessage,
              path: "customer.yearBankSeniority",
            });
          }

          // date must not be in future
          if (residenceDate.isAfter(moment())) {
            return this.createError({
              message: futureResidenceDateMessage,
              path: "customer.yearBankSeniority",
            });
          }

          // birthDate must be valid
          const birthDate = moment(this.options.values?.person?.birthDate);
          if (!birthDate.isValid()) {
            return this.createError({
              message: invalidBirthDateMessage,
              path: "customer.yearBankSeniority",
            });
          }

          //birthDate must be before bank seniority Date
          if (birthDate.isAfter(residenceDate)) {
            return this.createError({
              message: yearSeniorityBeforeBirthDateMessage,
              path: "customer.yearBankSeniority",
            });
          }

          //diff between birthDate and bank seniority date must be > 16 years
          const diff = residenceDate.year() - birthDate.year();
          if (diff < 16) {
            return this.createError({
              message: wrongDiffMessageBankSeniorty,
              path: "customer.yearBankSeniority",
            });
          }
        },
      }),
      address: yup.object().shape({
        residenceDate: yup.string().nullable()
        .test({
          name: "residenceDate verification",
          message: "",
          test(value) {
            // value is required
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.address.residenceDate",
              });
            }

            const residenceDate = moment(value);
            // in some case, moment's library return jsDates and the regex is needed, but let it last for perfs reasons
            if (!residenceDate.isValid() || (residenceDate.isValid() && (residenceDate.year() < 1000 || residenceDate.year() > 9999)) || !value.match(YYYYMMDDDateFormatRegex)) {
              return this.createError({
                message: dateYYYYFormatValidationMessage,
                path: "customer.address.residenceDate",
              });
            }

            // date must not be in future
            if (residenceDate.isAfter(moment())) {
              return this.createError({
                message: futureResidenceDateMessage,
                path: "customer.address.residenceDate",
              });
            }

            // birthDate must be valid
            const birthDate = moment(this.options.values?.person?.birthDate);
            if (!birthDate.isValid()) {
              return this.createError({
                message: invalidBirthDateMessage,
                path: "customer.address.residenceDate",
              });
            }

            //birthDate must be before residence Date
            if (birthDate.isAfter(residenceDate)) {
              return this.createError({
                message: residenceDateBeforeBirthDateMessage,
                path: "customer.address.residenceDate",
              });
            }

            //diff between birthDate and residenceDate must be > 16 years
            const diff = residenceDate.year() - birthDate.year();
            if (diff < 16) {
              return this.createError({
                message: wrongDiffMessage,
                path: "customer.address.residenceDate",
              });
            }
          },
        }),
        email: yup
        .string()
        .email(emailNotValidMessage)
        .required(requiredMessage),
        mobilePhone: yup
        .string()
        .nullable()
        .test({
          name: "mobilePhoneTest",
          message: "",
          test(value) {
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.address.mobilePhone",
              });
            }
            if (!phoneRegex.test(value)) {
              return this.createError({
                message: phoneMessage,
                path: "customer.address.mobilePhone",
              });
            }
          },
        }),
        address: yup
        .string().nullable()
        .test({
          name: "addressAddressTest",
          message: "",
          test(value) {
            // value is required
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.address.address",
              });
            }

            if (!value.match(maximumCaracterRegex(32))) {
              return this.createError({
                message: maximumCharactersMessage(32),
                path: "customer.address.address",
              });
            }
          },
        }),
        additionalAddress: yup
        .string()
        .nullable()
        .test({
          name: "additionalAddressTest",
          message: "",
          test(value) {
            if (!value) return false;
            if (!value.match(maximumCaracterRegex(32))) {
              return this.createError({
                message: maximumCharactersMessage(32),
                path: "customer.address.additionalAddress",
              });
            }
          },
        }),
        locality: yup
        .string().nullable()
        .test({
          name: "localityTest",
          message: "",
          test(value) {
            if (!value) return false;
            if (!value.match(maximumCaracterRegex(32))) {
              return this.createError({
                message: maximumCharactersMessage(32),
                path: "customer.address.locality",
              });
            }
          },
        }),
        postalCode: yup
        .string().nullable()
        .test({
          name: "addressAddressTest",
          message: "",
          test(value) {
            // value is required
            if (!value) {
              return this.createError({
                message: requiredMessage,
                path: "customer.address.postalCode",
              });
            }

            if (!value.match(postalCodeRegex)) {
              return this.createError({
                message: postalCodeMessage,
                path: "customer.address.postalCode",
              });
            }
          },
        }),
        city: yup.string().required(requiredMessage),
        housingType: yup.string().required(requiredMessage),
      }),
      netMonthlyIncome: yup
      .number().typeError(numberMessage)
      .integer(numberIntegerMessage)
      .required(requiredMessage)
      .moreThan(-1, mustBePositiveMessage),
      otherIncome : yup
      .number(numberMessage).typeError(numberMessage)
      .integer(numberIntegerMessage)
      .required(requiredMessage)
      .moreThan(-1, mustBePositiveMessage),
      rent : yup
      .number(numberMessage).typeError(numberMessage)
      .integer(numberIntegerMessage)
      .required(requiredMessage)
      .moreThan(-1, mustBePositiveMessage),
      otherCharges : yup
      .number(numberMessage).typeError(numberMessage)
      .integer(numberIntegerMessage)
      .required(requiredMessage)
      .moreThan(-1, mustBePositiveMessage),
      numberMonthsIncome: yup.number().required(requiredMessage),
      dependentChildrenNumber: yup
      .number().typeError(numberMessage)
      .integer(numberIntegerMessage)
      .max(10, childrenContainsMessage)
      .min(0, childrenContainsMessage)
      .required(requiredMessage),
      employmentDate: yup
      .string()
      .nullable()
      .test({
        name: "employment date verification",
        message: "",
        test(value) {
          let needEmploymentDate = this.options.values?.customer?.needEmploymentDate === "YES";
          if (!needEmploymentDate) {
            return true;
          }
          if (!value) {
            return this.createError({
              message: requiredMessage,
              path: "customer.employmentDate",
            });
          }
          const employmentDate = moment(value);
          // in some case, moment's library return jsDates and the regex is needed, but let it last for perfs reasons
          if (!employmentDate.isValid() || (employmentDate.isValid() && (employmentDate.year() < 1000 || employmentDate.year() > 9999)) || !value.match(YYYYMMDDDateFormatRegex)) {
            return this.createError({
              message: dateYYYYFormatValidationMessage,
              path: "customer.employmentDate",
            });
          }
          if (employmentDate.isAfter(moment())) {
            return this.createError({
              message: futureEmploymentDateMessage,
              path: "customer.employmentDate",
            });
          }
          const birthDateFormValue = this.options?.values?.person?.birthDate;
          if (!birthDateFormValue) {
            return this.createError({
              message: invalidBirthDateMessage,
              path: "customer.employmentDate",
            });
          }
          const birthDate = moment(birthDateFormValue);
          if (!birthDate.isValid()) {
            return this.createError({
              message: invalidBirthDateMessage,
              path: "customer.employmentDate",
            });
          }
          if (birthDate.isAfter(employmentDate)) {
            return this.createError({
              message: employmentDateBeforeBirthDateMessage,
              path: "customer.employmentDate",
            });
          }
          const diff = employmentDate.year() - birthDate.year();
          if (diff < 16) {
            return this.createError({
              message: wrongDiffMessage,
              path: "customer.employmentDate",
            });
          }
          return true;
        },
      }),
    }),
  });
const validate = makeValidate(schema);
export default validate;
