import React, { Component } from "react";
import { Field as FinalFormField } from "react-final-form";
import { Alert, Form, FormGroup, Col } from "react-bootstrap";
import { AccessKeysContext } from "../context/AccessKeysContext";
import { REQUIRED_MESSAGE, INVALID_DATE_MESSAGE } from "./defaultMessages";
const moment = require("moment");

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export default class DateField extends Component {
  state = {
    touched: false,
  };

  handleOnChange(event, input, value, hasErrorWithoutBeingTouched){
    if (hasErrorWithoutBeingTouched || this.state.touched === true) {
      let dateServerFormatted = this.handleDateChange(event, input, value);
      input.onChange(dateServerFormatted);
    }
  }

  handleOnBlur(event, input, value) {
    let dateServerFormatted = this.handleDateChange(event, input, value);
    input.onChange(dateServerFormatted);
  }

  handleDateChange = (event, input, accessKeys) => {
    const { value } = event.target;
    const { name } = this.props;
    const ak = accessKeys[name] || {};
    const { dateFormat = DEFAULT_DATE_FORMAT, dateFormatServer } = {
      ...this.props,
      ...ak,
    };

    if (!this.state.touched === true) {
      this.setState({touched : true});
    }
    let momentFromString = moment(value, dateFormat, true);
    return momentFromString.isValid() ? momentFromString.format(dateFormatServer) :  value;
  };

  render() {
    return (
      <AccessKeysContext.Consumer>
        {(accessKeys = {}) => {
          const { name } = this.props;
          const ak = accessKeys[name] || {};
          const {
            hidden,
            placeholder,
            label,
            className,
            xs,
            lg,
            xl,
            md,
            sm,
            xsHidden,
            smHidden,
            mdHidden,
            lgHidden,
            xlHidden,
            disabled,
            hasServerErrors,
            noFormGroup,
            dateFormat,
            dateFormatServer,
            initialValue
          } = { ...this.props, ...ak };

          if (hidden) {
            return false;
          }

          return (
            <FinalFormField
              name={name}
              component="input"
              type="text"
              placeholder={placeholder}
              className="form-control"
              initialValue={initialValue}
            >
              {({ input, meta }) => {
                const metaError = meta.error && (this.state.touched === true || hasServerErrors);
                const hasError = metaError || (meta.submitFailed === true && meta.valid === false) || meta.submitError;
                const cl = {
                  "d-none":
                    xsHidden || smHidden || mdHidden || lgHidden || xlHidden,
                  "d-sm-block": !smHidden,
                  "d-sm-none": smHidden,
                  "d-md-block": !mdHidden,
                  "d-md-none": mdHidden,
                  "d-lg-block": !lgHidden,
                  "d-lg-none": lgHidden,
                  "d-xl-block": !xlHidden,
                  "d-xl-none": xlHidden,
                };
                if (className) {
                  cl[className] = true;
                }

                const hasColParam =
                  xs ||
                  lg ||
                  xl ||
                  md ||
                  sm ||
                  xsHidden ||
                  smHidden ||
                  mdHidden ||
                  lgHidden ||
                  xlHidden;
                const colParams = { className: cl, xs, lg, xl, md, sm };
                let body = [
                  <React.Fragment key="label">
                    {label && <Form.Label>{label}</Form.Label>}
                  </React.Fragment>,
                  <Form.Control
                    key="input"
                    defaultValue={
                      meta.initial
                        ? moment(meta.initial, dateFormatServer).format(
                            dateFormat,
                          )
                        : ""
                    }
                    onBlur={value => {
                      this.handleOnBlur(value, input, accessKeys);
                    }}
                    onChange={value => {
                      this.handleOnChange(value, input, accessKeys, (meta.submitFailed === true && meta.valid === false));
                    }}
                    className={hasError && "datefield-error"}
                    type="text"
                    placeholder={placeholder ? placeholder : dateFormat}
                    disabled={disabled}
                  />,
                  <React.Fragment key="error">
                    {hasError && (
                      <p className="required-message">
                        {meta.error || meta.submitError}
                      </p>
                    )}
                  </React.Fragment>,
                ];

                if (!noFormGroup) {
                  body = <FormGroup>{body}</FormGroup>;
                }

                if (hasColParam) {
                  body = <Col {...colParams}>{body}</Col>;
                }

                return body;
              }}
            </FinalFormField>
          );
        }}
      </AccessKeysContext.Consumer>
    );
  }
}
