import React, { Component } from "react";
import { getAuthorizationsToken } from "../core/utils";

class SignSepaMandate extends Component {
  state = {};

  componentDidMount() {}

  setStateFromInput = event => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  render() {
    let contract = this.props.contract;
    let quotation = contract.quotations[0];
    let client = this.props.client;

    let now = new Date();
    let data = {
      bankName: "So You",
      bankAddress: "Julian Camarillo, 4",
      bankCity: "Madrid",
      bankZip: "92000",
      nameOfAccountHolder: client.firstName + " " + client.lastName,
      iban: this.state.iban,
      bic: (this.state.iban || "ES60").substring(0, 4),
      postalAddress: this.state.address,
      reference: this.state.iban,
      identifier: "12345",
      date: now.toLocaleDateString("es-ES")
    };

    let printShopUrl;
    if (window.location.origin.startsWith("https://")) {
      printShopUrl =
        "/api/report/reports/sepa-mandate.docx/run?contentDisposition=inline&data=" +
        JSON.stringify(data) +
        "&token=" +
        getAuthorizationsToken();
    }

    return (
      <div className="modal-financing">
        <div>
          <span className="payment-schedule-total">{quotation.simulation.formattedCurrency}</span>
          <h5>My purchase</h5>
          <p>Date: {quotation.simulation.formattedDate}</p>
        </div>
        <div>
          <h5>Pick your preferred IBAN</h5>
          <select name="iban" value={this.state.iban} onChange={this.setStateFromInput}>
            {[
              "",
              "ES60 3526 9688 2635 5129 0222",
              "ES68 7573 1311 3523 2618 2382",
              "ES02 3782 8656 2350 5678 4698"
            ].map(it => (
              <option value={it}>{it}</option>
            ))}
          </select>
          <iframe
            id="electronicSignatureIFrame"
            width="90%"
            height="400"
            title="Electronic Signature - SEPA Mandate"
            src={printShopUrl}
          />
        </div>
        {
          /*this.state.iban &&*/ <div className="financing-button-div OTP-div">
            <button disabled={!this.state.iban} type="button" className="btn-solid btn">
              Send OTP
            </button>
            <input
              type="text"
              name="OTP"
              value={this.state.OTP}
              onChange={event => event.target.value && event.target.value.length > 3 && this.setStateFromInput(event)}
            />
          </div>
        }
        <div className="financing-button-div">
          <button
            disabled={!this.state.OTP}
            type="button"
            className="btn-solid btn"
            onClick={() => this.props.onSetStep(+1)}
          >
            Sign
          </button>
        </div>
        <div className="text-right">
          <button type="button" className="btn-simple" onClick={() => this.props.onSetStep(-1)}>
            back
          </button>
        </div>
      </div>
    );
  }
}

export default SignSepaMandate;
