import axios from "axios";
import { fr_FR } from "./fr-FR";
import { convertDateStringsToDates } from "./utils.common";
import EventEmitter from "eventemitter3";

export function isEmpty(s) {
  return !!!s;
}

export var debug = false;
export function setDebug(d) {
  debug = d;
}

// **********************************
// Localization
// **********************************
var _localizedStrings = { isEnglish: true };
function loc(strings, ...values) {
  if (!strings) return strings;
  // TODO CLEMENT régler la langue par défaut sur tunnelData.data.order.language
  if (!_localizedStrings.isLoaded) {
    let config = userConfigurationService.getUserConfiguration();
    if (config) {
      if (config.user.locale === "fr-FR") {
        _localizedStrings = fr_FR;
        _localizedStrings.isEnglish = false;
      } else if (!config.user.locale || config.user.locale === "en-GB" || config.user.locale === "en-US") {
        // do nothing
      } else {
        notificationService.addNotification(
          `Unsupported locale "${config.user.locale}" for user "${config.user.username}"`,
          "error"
        );
      }
      if (config.overrides) _localizedStrings = { ..._localizedStrings, ...config.overrides };
      _localizedStrings.isLoaded = true;
    }
  }

  if (typeof strings === "string") strings = [strings];
  let key = strings.join("$");
  let lstrings = _localizedStrings[key];

  let missing;
  if (!lstrings) {
    lstrings = strings;
    if (strings.length === 1) lstrings = lstrings[0];
    _localizedStrings[key] = lstrings;
    missing = true;
  }
  if (missing && debug && !_localizedStrings.isEnglish) {
    console.log("Missing localized:<<" + key + ">>", _localizedStrings.isEnglish);
  }

  strings = lstrings;
  let lstr;
  if (typeof strings === "string") {
    lstr = strings;
  } else {
    let array = [];
    for (let i = 0; i < values.length; i++) {
      array.push(strings[i], values[i]);
    }
    array.push(strings[values.length]);
    lstr = array.join("");
  }
  return lstr;
}

// **********************************
// Axios
// **********************************
export function formatAxiosError(error) {
  error &&
    console.log(
      "===",
      error,
      error.response,
      error.response && error.response.data,
      error.response && error.response.data && error.response.data.message
    );
  let msg;
  if (error.response && error.response.data) {
    msg = debug ? error.response.data.stack : error.response.data.message || error.response.data;
    let data = error.response.data;
    if (debug && data.stack) {
      msg = data.stack;
    } else {
      let array = data.strings;
      if (array) msg = loc(data.message, ...array);
      else msg = error.response.data.message || error.response.data || error.message;
    }
    msg += " (" + error.response.status + ")";
  }
  if (!msg) msg = debug && error.stack ? error.stack : error.message;
  return msg;
}

var _headersAuthorizations;
export function getHeadersAuthorizations() {
  return _headersAuthorizations || window.localStorage.getItem("headersAuthorizations");
}

export function getAuthorizationsToken() {
  return getHeadersAuthorizations().substring("bearer ".length);
}

export function setupAxiosClient(baseUrl) {
  function sameOrigin(url) {
    return url.startsWith("/api") || url.startsWith(baseUrl);
  }

  _headersAuthorizations = getHeadersAuthorizations();

  axios.interceptors.request.use(
    function(config) {
      if (spinnerService) spinnerService.show();
      if (sameOrigin(config.url) && _headersAuthorizations) config.headers.Authorization = _headersAuthorizations;
      return config;
    },
    function(error) {
      if (spinnerService) spinnerService.hide();
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function(response) {
      convertDateStringsToDates(response.data);
      if (sameOrigin(response.request.responseURL)) {
        let headers = response.headers;
        if (headers && (headers.hasOwnProperty("Authorization") || headers.hasOwnProperty("authorization"))) {
          let auth = headers && (headers.Authorization || headers.authorization);
          _headersAuthorizations = auth;
          window.localStorage.setItem("headersAuthorizations", auth);
        }
      }
      if (spinnerService) spinnerService.hide();
      console.log("End calling NO error", response.config.url, "(" + response.status + ")");
      return response;
    },
    function(error) {
      if (spinnerService) spinnerService.hide();
      if (!error.response) {
        console.log("End calling WITH error", error.config && error.config.url);
        console.log("Error:", error);
      } else {
        console.log("End calling WITH error", error.config && error.config.url, "(" + error.response.status + ")");
        console.log("Response data:", error.response.data);
        if (sameOrigin(error.response.request.responseURL) && error.response.status === 401) {
          if (authenticationService.isAuthenticated()) authenticationService.userHasLoggedOut();
        }
      }
      return Promise.reject(error);
    }
  );
}

//***********************************
// Locale & formats
//***********************************
/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
function isValidIban(input) {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26
  };
  var iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits) === 1;
}
function mod97(string) {
  var checksum = string.slice(0, 2),
    fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

// **********************************
// ListsService
// **********************************
class ListsService {
  constructor() {
    this.listCache = {};
  }

  register(list) {
    list.values = list.values.map(item =>
      item.label ? { ...item, label: loc(item.label) } : { ...item, label: item.value }
    );
    list.labels = {};
    list.items = {};
    for (let i = 0; i < list.values.length; i++) {
      let item = list.values[i];
      list.labels[item.value] = item.label || item.value;
      list.items[item.value] = item;
    }
    this.listCache[list.name] = list;
  }

  reset() {
    this.listCache = {};
  }

  deregister(name) {
    delete this.listCache[name];
  }

  deregisterAll() {
    this.listCache = {};
  }

  getList(name, handleLoaded) {
    if (this.listCache[name]) {
      let list = this.listCache[name];
      if (handleLoaded && list.handleLoadedCallbacks) {
        // list is loading, add callback
        if (list.handleLoadedCallbacks.length < 100) {
          // 100 max in case there is an infinite loop caused by some bug
          list.handleLoadedCallbacks.push(handleLoaded);
        }
      }
      return list;
    } else {
      this.register({
        name,
        values: [],
        handleLoadedCallbacks: (handleLoaded && [handleLoaded]) || []
      });
      let url = name.startsWith("/") ? name : "/api/script/lists?lists=" + name;
      axios
        .get(url)
        .then(response => {
          let data = response.data;
          let loadingLists = [];
          // DO NOT CHANGE ANYTHING UNLESS YOU KNOW WHAT YOU ARE DOING !!
          // here this is tricky because in case of list we can have lots of hooks to call
          // first replace all loading list (with callbacks) with there loaded version (without callbacks)
          for (let dataItem of data) {
            dataItem.name = name;
            let loadingList = this.listCache[dataItem.name];
            if (loadingList && loadingList.handleLoadedCallbacks) loadingLists.push(loadingList);
            this.register(dataItem);
          }
          // then execute callbacks
          for (let loadingList of loadingLists) {
            let loadedList = this.listCache[loadingList.name];
            loadingList.handleLoadedCallbacks.forEach(handleLoaded => handleLoaded(loadedList));
          }
        })
        .catch(error => {
          console.log(error);
          notificationService.addNotification(loc`List not found '${name}': ${error.message}`, "error");
        });
      return this.listCache[name];
    }
  }

  getValues(name, handleLoaded) {
    let list = this.getList(name, handleLoaded);
    if (list) return list.values;
    return undefined;
  }

  getLabel(listName, value, handleLoaded) {
    if (!value) return value;
    let list = this.getList(listName, handleLoaded);
    return list ? list.labels[value] || value : value;
  }

  getItem(listName, value, handleLoaded) {
    if (!value) return value;
    let list = this.getList(listName, handleLoaded);
    return list ? list.items[value] || value : value;
  }
}

// **********************************
// SpnnerService
// **********************************
class SpinnerService {
  constructor() {
    this.spinnerComponent = null;
  }
  register(sc) {
    this.spinnerComponent = sc;
  }
  show() {
    if (this.spinnerComponent) this.spinnerComponent.show();
  }
  hide() {
    if (this.spinnerComponent) this.spinnerComponent.hide();
  }
}

// **********************************
// NotificationService
// **********************************
class NotificationService {
  constructor() {
    this.notificationComponent = null;
    //this.getValues = this.getValues.bind(this);
    //this.register  = this.register.bind(this);
  }
  register(nc) {
    this.notificationComponent = nc;
  }
  unregister(nc) {
    this.notificationComponent = null;
  }
  addNotification(message, level = "success", position = "br", action) {
    //if (!action && level === "info") action = { label: "Install it!", callback: () => {}};
    this.notificationComponent &&
      this.notificationComponent.handleNotification(message, level, position, action ? 30 : 5, action);
  }
  addOups(what, errorException) {
    what = loc(what);
    let message = loc`Oups, something went wrong`;
    if (what) message += " " + what;
    if (errorException) message += ": " + formatAxiosError(errorException);
    this.addNotification(message, "error");
    console.log(errorException);
  }
  getHistory() {
    return this.notificationComponent && this.notificationComponent.props.history;
  }
}

export function arrayIntersects(array1, array2) {
  if (array1 && array2) {
    if (!Array.isArray(array1)) array1 = [array1];
    if (!Array.isArray(array2)) array2 = [array2];
    for (let it1 of array1) {
      for (let it2 of array2) {
        if (it1 === it2) return true;
      }
    }
  }
  return false;
}

// **********************************
// UserConfiguration
// **********************************
class UserConfigurationService {
  constructor() {
    this.userConfiguration = null;
  }

  // register(component) {
    // this.component = component;
  // }

  // async loadUserConfiguration(force = false) {
    // if (!force && this.userConfiguration) {
      // return this.userConfiguration;
    // } else {
      // try {
        // this.userConfiguration = (await axios.get("/api/script/runs/user-configuration")).data;
        // let userProfiles = this.userConfiguration.user.profiles;
        // this.menus = this.userConfiguration.menus.filter(
          // it => !it.profiles || arrayIntersects(it.profiles, userProfiles)
        // );
      // } catch (error) {
        // notificationService.addOups(loc`loading user configuration`, error);
      // }
      // try {
        // this.userConfiguration.overrides = (await axios.get("/api/script/runs/string-overrides")).data;
      // } catch (error) {
        // notificationService.addOups(loc`loading string overrides`, error);
      // }
      // this.component.setState({ isUserConfigurationLoaded: true });
      // return this.userConfiguration;
    // }
  // }

  register(configuration) {
    this.userConfiguration = configuration;
  }

  unloadUserConfiguration() {
    this.userConfiguration = null;
  }

  getUserConfiguration() {
    return this.userConfiguration;
  }

  getMenus() {
    return this.menus;
  }

  getUserId() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user._id;
  }

  getUsername() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user.username;
  }

  getLocale() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user.locale;
  }

  getTenant() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user.tenant;
  }

  getProfiles() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user.profiles;
  }

  hasProfile(profile) {
    let config = this.userConfiguration;
    return config && config.user && arrayIntersects(config.user.profiles, profile);
  }

  getIsAdmin() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user.isAdmin;
  }

  getIsMainAdmin() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user.isMainAdmin;
  }

  getIsSuperAdmin() {
    let config = this.userConfiguration;
    if (config && config.user) return config.user.isSuperAdmin;
  }

  getScriptOverride(scriptName) {
    let config = this.userConfiguration;
    if (config && config && config.scriptOverrides) return config.scriptOverrides[scriptName];
  }
}

// **********************************
// **********************************
// AuthenticationServie
// **********************************
class AuthenticationService extends EventEmitter {
  constructor() {
    super();
    this.authenticated = true;
  }

  /**
  async authenticationCheck() {
    try {
      return (await axios.get("/api/authentication-check")) && true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
   */

  isAuthenticated() {
    return this.authenticated;
  }

  userHasLoggedOut() {
    this.authenticated = false;
    this.emit({ authenticated: false });
  }

  userHasLoggedIn() {
    this.authenticated = true;
    this.emit({ authenticated: true });
  }
}

function resetServices() {
  listsService.reset();
  userConfigurationService.userConfiguration = null;
  userConfigurationService.loadUserConfiguration(true);
  //notificationService.unregister();
  _localizedStrings = { isEnglish: true };
}

export function downloadFileFromUrl(url, okMessage, koMessage) {
  axios
    .get(url, { responseType: "blob" })
    .then(response => {
      // Try to find out the filename from the content disposition `filename` value
      const disposition = response.headers["content-disposition"];
      if (!disposition) {
        return;
      }
      const matches = /"([^"]*)"/.exec(disposition); // TODO: tell Thomas, does not extract filename (in my case; "attachment; filename=theDocument.xml")

      let filename = "file.docx";
      if (matches != null && matches[1]) {
        filename = matches[1];
      } else {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1];
        }
      }

      // The actual download
      var blob = new Blob([response.data]);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);

      link.click();

      // const url = window.URL.createObjectURL(new Blob([response.data]));
      // const link = report.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'file.docx');
      // report.body.appendChild(link);
      // link.click();

      document.body.removeChild(link);

      notificationService.addNotification(loc(okMessage));
    })
    .catch(async error => {
      // in case of error we get a blob so we need to fix it to display a proper error
      try {
        if (error.response && error.response.data && error.response.data instanceof Blob) {
          let response = await new Response(error.response.data).text();
          let json = JSON.parse(response);
          error.response.data = json;
        }
      } catch {}
      notificationService.addOups(loc(koMessage), error);
      throw error;
    });
}

export function searchParamToObject(query) {
  let object = {};
  const searchParam = new URLSearchParams(query);
  for (let [field, value] of searchParam) object[field] = value;
  return object;
}

export function objectToSearchParam(prefix, object) {
  // this works but the URL is ugly with commas and it is unnecessary since modern browser are permissive
  // const searchParam = new URLSearchParams();
  // for (let field in object) {
  // if (object[field]) searchParam.append(field, object[field]);
  // }
  // let str = searchParam.toString();
  // return str ? "?" + str : str;

  // we use this version instead
  let str = Object.keys(object)
    .map(field => object[field] && field + "=" + object[field])
    .filter(it => it)
    .join("&");
  return prefix && str ? prefix + str : str;
}

const authenticationService = new AuthenticationService();
const notificationService = new NotificationService();
const spinnerService = new SpinnerService();
const listsService = new ListsService();
const userConfigurationService = new UserConfigurationService();
export {
  loc,
  isValidIban,
  authenticationService,
  notificationService,
  listsService,
  spinnerService,
  userConfigurationService,
  resetServices
};
