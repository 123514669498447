import React, {Component} from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import defaultLogo from "../../asset/img/logo.jpg";
import * as FinalForm from "react-final-form";
import TextField from "../../form/TextField";
import RadiosField from "../../form/RadiosField";
import DateField from "../../form/DateField";
import SelectField from "../../form/SelectField";
import _ from "lodash";
import {loc} from "../../core/utils";
import validate from "./validate";
import {
  REPOSITORY_SEPARATOR,
  convertPersonTitleCodeToEnumName,
  FRANCE_COUNTRY_CODE,
  isASocioProfessionalCategoryWithoutContract,
  missEnumName,
  convertTitleEnumNameToCode, getCivilStatuses, missEnumCode
} from "../../core/service/BusinessLogicService";

const emptyTunnelData = { data: {} };
const DATE_FORMAT = "DD/MM/YYYY"; // todo modularité
const YEAR_FORMAT = "YYYY";  // todo modularité

class Client3Page extends Component {
  state = {
    submitButtonLocked : false,
  };

  async componentDidMount() {
    this.setState({"civilStatuses" : await getCivilStatuses(this.props?.tunnelData, true)});
  }

  lockSubmitButton = async () => {
    await this.setState({submitButtonLocked : this.state.submitButtonLocked !== true});
  };

  onSubmit = async values => {
    await this.lockSubmitButton();
    if (!values.person.title && this.props.tunnelData?.data?.order?.customer?.person?.title) {
      values.person.title = this.props.tunnelData.data.order.customer.person.title;
    }
    if (values.person.title !== missEnumCode && values.person.maidenName) {
      values.person.maidenName = undefined;
    }
    if (values.customer.employmentDate
        && isASocioProfessionalCategoryWithoutContract(
            this.props.tunnelData.socioProfessionalcategoriesWithoutContract,
            values.customer.occupationCode)) {
      values.customer.employmentDate = undefined;
    }
    const submitErrors = await this.props.onSetStep(values);
    console.log(submitErrors);
    return submitErrors;
  };

  getSocioProfessionalCategories() {
    let tunnelData = this.props.tunnelData;
    let socioProfessionalCategories =
        tunnelData.socioProfessionalcategories || [];
    let result = [];
    for (const [key, value] of Object.entries(socioProfessionalCategories)) {
      result.push({ value: key, label: value });
    }
    result.sort(function(scp, otherScp) {
      if (scp.label < otherScp.label) {
        return -1;
      }
      if (scp.label > otherScp.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  getbirthDepartments() {
    let tunnelData = this.props.tunnelData;
    let birhtDepartments = tunnelData.birhtDepartments || [];
    let result = [];
    for (const [key, value] of Object.entries(birhtDepartments)) {
      result.push({ value: key, label: key + REPOSITORY_SEPARATOR + value });
    }
    result.sort(function(birhtDepartment, otherbirhtDepartments) {
      if (birhtDepartment.label < otherbirhtDepartments.label) {
        return -1;
      }
      if (birhtDepartment.label > otherbirhtDepartments.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  getCountries() {
    let tunnelData = this.props.tunnelData;
    let countries = tunnelData.countries || [];
    let result = [];
    for (const [key, value] of Object.entries(countries)) {
      result.push({ value: key, label: value });
    }
    result.sort(function(reference, otherReference) {
      if (reference.label < otherReference.label) {
        return -1;
      }
      if (reference.label > otherReference.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  getHousingTypes() {
    let tunnelData = this.props.tunnelData;
    let housingTypes = tunnelData.housingTypes || [];
    let result = [];
    for (const [key, value] of Object.entries(housingTypes)) {
      result.push({ value: key, label: value });
    }
    result.sort(function(reference, otherReference) {
      if (reference.label < otherReference.label) {
        return -1;
      }
      if (reference.label > otherReference.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  getFamilySituations() {
    let tunnelData = this.props.tunnelData;
    let familySituations = tunnelData.familySituations || [];
    let result = [];
    for (const [key, value] of Object.entries(familySituations)) {
      result.push({ value: key, label: value });
    }
    result.sort(function(reference, otherReference) {
      if (reference.label < otherReference.label) {
        return -1;
      }
      if (reference.label > otherReference.label) {
        return 1;
      }
      return 0;
    });
    return result;
  }

  // todo clement formatter le bic et l'iban auto + ameliorer visuel saisie
  render() {
    const { removeTunnelDataError, tunnelData = emptyTunnelData } = this.props;
    const hasServerErrors = !!tunnelData.errors;
    let customer = this.loadCustomerDataRelyingOnComponentContext();
    let person = this.loadPersonDataRelyingOnComponentContext();

    return (
        <>
          {/* ————— TOPBAR ————— */}
          <header>
            <nav className="text-center">
              <Row className="justify-content-center no-gutters">
                <Col xs={5} md={3} lg={2}>
                  <a href="https://www.uzit-direct.com/" title="UZ'it">
                    <img src={defaultLogo} alt="UZ'it" />
                  </a>
                </Col>
              </Row>
            </nav>
          </header>
          <Row className="justify-content-center no-gutters">
            <Col sm={12}>
              <ul className="breadcrumb">
                <li>{loc`My addresses`}</li>
                <li>{loc`My credit card data`}</li>
                <li className="active">{loc`My contract`}</li>
                <li>{loc`Finalization`}</li>
              </ul>
            </Col>
          </Row>

          {/* ————— CONTENT ————— */}
          <Container>
            <Row>
              <Col lg={12} sm={12}>
                <h1>{loc`My contract`}</h1>
                <div className="data">
                  <p className="eligibility">
                    {loc`The information below is necessary for our renter for the constitution of your rental file.`}
                    <br/>
                    {loc`To meet our eligibility conditions, you must have regular activity income and not have any current payment incidents.`}
                  </p>
                  <FinalForm.Form
                      initialValues={tunnelData.data}
                      validate={ async values => {
                        if (!values.person.title) {
                          values.person.title = this.props.tunnelData.data.order.customer.person.title;
                        }
                        if (values.person?.birthCountryCode !== FRANCE_COUNTRY_CODE && values.person?.birthDepartmentCode !== this.props?.tunnelData?.outOfFranceDepartment?.[0].value) {
                          values.person.birthDepartmentCode = this.props?.tunnelData?.outOfFranceDepartment?.[0].value;
                        }
                        if (values.person?.birthCountryCode === FRANCE_COUNTRY_CODE && values.person?.birthDepartmentCode === this.props?.tunnelData?.outOfFranceDepartment?.[0].value) {
                          values.person.birthDepartmentCode = null;
                        }
                        if (values.person?.birthCountryCode === FRANCE_COUNTRY_CODE && values.person?.birthDepartmentCode && values.person?.birthCity ) {
                          let birthDepartmentIsCorsia = (values.person.birthDepartmentCode === "2A" || values.person.birthDepartmentCode === "2B");
                          let zipCodeExtractedFromInputValue = values.person.birthCity.substr(values.person.birthCity.indexOf(REPOSITORY_SEPARATOR)+3, values.person.birthCity.indexOf(REPOSITORY_SEPARATOR) + 3);
                          let zipCodeExtractedNotNullOrEmpty = zipCodeExtractedFromInputValue && zipCodeExtractedFromInputValue.length > 1;
                          let zipCodeExtractedFromInputValueNotContainsBirthDepartmentCode = zipCodeExtractedNotNullOrEmpty && !birthDepartmentIsCorsia && !zipCodeExtractedFromInputValue.substr(0,values.person.birthDepartmentCode.length).includes(values.person.birthDepartmentCode);
                          let birthDepartmentIsCorsiaAndZipCodeExtractedDoesNotContainsDptCode = zipCodeExtractedNotNullOrEmpty && birthDepartmentIsCorsia && !zipCodeExtractedFromInputValue.substr(0,values.person.birthDepartmentCode.length).includes("20");
                          let shouldPersonBirthCityBeDeleted = zipCodeExtractedFromInputValueNotContainsBirthDepartmentCode || birthDepartmentIsCorsiaAndZipCodeExtractedDoesNotContainsDptCode;
                          if (shouldPersonBirthCityBeDeleted) {
                            values.person.birthCity = null;
                          }
                        }
                        if (values.customer && values.customer.needEmploymentDate === "YES" && this.props.tunnelData && this.props.tunnelData.socioProfessionalcategoriesWithoutContract && values.customer.occupationCode && await isASocioProfessionalCategoryWithoutContract(this.props.tunnelData.socioProfessionalcategoriesWithoutContract, values.customer.occupationCode)) {
                          values.customer.needEmploymentDate = undefined;
                        }
                        // server side errors
                        const res = await validate(values);
                        if (!_.isEmpty(res)) {
                          return res;
                        }
                        const {tunnelData = {}} = this.props;
                        let errors = {};
                        if (!tunnelData.errors) {
                          return;
                        }
                        tunnelData.errors.forEach(error => {
                          const valueNotChanged =
                              _.get(tunnelData.data, error.path) ===
                              _.get(values, error.path);
                          if (valueNotChanged) {
                            _.set(errors, error.path, error.message);
                          } else {
                            removeTunnelDataError(error.path);
                          }
                        });
                        return errors;
                      }}
                      onSubmit={this.onSubmit}
                      render={ ({
                        handleSubmit,
                        values,
                        submitting,
                        validating,
                        valid,
                      }) => {
                        let birthCountryCodeNotFrance = values && values.person  && values.person.birthCountryCode && values.person.birthCountryCode !== FRANCE_COUNTRY_CODE;
                        let birthCityShouldBeTextField = (birthCountryCodeNotFrance && values?.person?.birthDepartmentCode === tunnelData?.outOfFranceDepartment?.[0].value);
                        let birthCityShouldBeSelectField = (!birthCountryCodeNotFrance && values.person.birthDepartmentCode && tunnelData && tunnelData.outOfFranceDepartment && values.person.birthDepartmentCode !== tunnelData.outOfFranceDepartment[0].value);
                        let socioProfessionalCategoryNeedsContractDate = (!values
                            || !values.customer
                            || !values.customer.occupationCode)
                            || (values.customer.occupationCode
                                && !isASocioProfessionalCategoryWithoutContract(
                                    tunnelData.socioProfessionalcategoriesWithoutContract,
                                    values.customer.occupationCode));
                        return (
                            <Form onSubmit={handleSubmit}>
                              <Row className="justify-content-center ">
                                <Col lg={11} md={9}>
                                  <Row>
                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-id-card"></i>{" "}
                                        {loc`Identity`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <RadiosField
                                            name="person.title"
                                            items={this.state.civilStatuses || []}
                                            label={loc`Title`}
                                            hasServerErrors={hasServerErrors}
                                            initialCustomValue={person.title}
                                        />
                                        <TextField
                                            name="person.name"
                                            label={loc`Last name`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={person.name}
                                            trimOnBlur={true}
                                        />

                                        {((values && values.person
                                        && values.person.title === missEnumCode) || (!values.person.title && person.title === missEnumCode))
                                        && <FormGroup>
                                          <Form.Label>{loc`Maiden name`}</Form.Label>
                                          <Row noGutters>
                                            <Col lg={10} xs={10}>
                                              <TextField
                                                  noFormGroup
                                                  name="person.maidenName"
                                                  hasServerErrors={hasServerErrors}
                                                  initialValue={person.maidenName}
                                                  trimOnBlur={true}
                                              />
                                            </Col>
                                            <Col lg={2} xs={2}>
                                              <OverlayTrigger
                                                  key="left"
                                                  placement="left"
                                                  overlay={
                                                    <Tooltip>{loc`Si vous n'êtes pas mariée, saisissez à nouveau votre nom.`}</Tooltip>
                                                  }
                                              >
                                                <Button variant="dark">
                                                  <i className="fas fa-question"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </Col>
                                          </Row>
                                        </FormGroup>}
                                        <TextField
                                            name="person.firstName"
                                            label={loc`First name`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={person.firstName}
                                            trimOnBlur={true}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-map-marker-alt"></i>{" "}
                                        {loc`Address`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <TextField
                                            name="customer.address.address"
                                            label={loc`Address`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.address.address}
                                            trimOnBlur={true}
                                        />
                                        <TextField
                                            name="customer.address.additionalAddress"
                                            label={loc`Address 2`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={
                                              customer.address.additionalAddress
                                            }
                                            trimOnBlur={true}
                                        />
                                        <TextField
                                            name="customer.address.locality"
                                            label={loc`Locality`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.address.locality}
                                            trimOnBlur={true}
                                        />
                                        <TextField
                                            name="customer.address.postalCode"
                                            label={loc`Zip`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.address.postalCode}
                                            trimOnBlur={true}
                                        />
                                        <TextField
                                            name="customer.address.city"
                                            label={loc`City`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.address.city}
                                            trimOnBlur={true}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-birthday-cake"></i>{" "}
                                        {loc`Birth`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <DateField
                                            name="person.birthDate"
                                            label={loc`Birthdate`}
                                            placeholder={loc`YYYY/MM/DD`}
                                            dateFormat={DATE_FORMAT}
                                            dateFormatServer={"YYYY-MM-DD"}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.birthDate}
                                        />
                                        <SelectField
                                            name="person.birthCountryCode"
                                            label={loc`Country of birth`}
                                            placeholder={loc`Select...`}
                                            options={this.getCountries()}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={person.birthCountryCode}
                                        />
                                        { birthCountryCodeNotFrance === false  && <SelectField
                                          name="person.birthDepartmentCode"
                                          placeholder={loc`Select...`}
                                          label={loc`Department of birth`}
                                          options={this.getbirthDepartments()}
                                          hasServerErrors={hasServerErrors}
                                          initialValue={person.birthDepartmentCode}
                                        />}
                                        {birthCountryCodeNotFrance === true && <SelectField
                                          name="person.birthDepartmentCode"
                                          placeholder={loc`Select...`}
                                          label={loc`Department of birth`}
                                          options={tunnelData.outOfFranceDepartment || [{"value": "????", "label": "????" + REPOSITORY_SEPARATOR + "????"}]}
                                          hasServerErrors={hasServerErrors}
                                          initialValue={tunnelData.outOfFranceDepartment[0].value}
                                        />}
                                        {birthCityShouldBeTextField && <TextField
                                            name="person.birthCity"
                                            label={loc`City of birth`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={person.birthCity}
                                            trimOnBlur={true}
                                        />}
                                        {birthCityShouldBeSelectField &&
                                          <SelectField
                                            name="person.birthCity"
                                            label={loc`City of birth`}
                                            initialValue={person.birthCity}
                                            placeholder={loc`Select...`}
                                            otherComponentValueToBind={values.person.birthDepartmentCode}
                                            restApiConfig={
                                              { url: this.props.tunnelData.apiUrl + "middle-business-service/api/v2/repository/cities/"+ values.person.birthDepartmentCode +"/false/notused",
                                              headers: {
                                                Authorization: `Bearer ${this.props.tunnelData.access_token}`,
                                                Accept: "*/*"
                                              }
                                              }
                                            }
                                            label={loc`City of birth`}
                                            options={[{"value": "???", "label": "????" + REPOSITORY_SEPARATOR + "????"}]}
                                            hasServerErrors={hasServerErrors}
                                          />}
                                        <SelectField
                                            name="person.citizenshipCountryCode"
                                            label={loc`Citizenship`}
                                            placeholder={loc`Select...`}
                                            options={this.getCountries()}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={person.citizenshipCountryCode}
                                        />
                                      </div>
                                    </Col>

                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-phone"></i> {loc`Contact`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <TextField
                                            name="customer.address.mobilePhone"
                                            label={loc`Mobile`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.address.mobilePhone}
                                            trimOnBlur={true}
                                        />
                                        <TextField
                                            name="customer.address.email"
                                            label={loc`Email`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.email}
                                            trimOnBlur={true}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <hr className="invisible"></hr>

                              <Row className="justify-content-center">
                                <Col lg={11} md={9}>
                                  <Row>
                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-briefcase"></i>
                                        {loc`Occupation`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <SelectField
                                            name="customer.occupationCode"
                                            label={loc`Occupation`}
                                            placeholder={loc`Select...`}
                                            options={this.getSocioProfessionalCategories()}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.occupationCode}
                                        />
                                        {socioProfessionalCategoryNeedsContractDate
                                        && <div>
                                          <DateField
                                              name="customer.employmentDate"
                                              label={loc`Since...`}
                                              placeholder={loc`YYYY`}
                                              dateFormat={YEAR_FORMAT}
                                              dateFormatServer={"YYYY-MM-DD"}
                                              hasServerErrors={hasServerErrors}
                                              initialValue={customer.employmentDate}
                                          />
                                          <div style={{visibility: 'hidden'}}>
                                            <TextField
                                                name="customer.needEmploymentDate"
                                                initialValue={"YES"}
                                                label={"technical"}
                                                hasServerErrors={hasServerErrors}
                                            />
                                          </div>
                                        </div>}
                                      </div>
                                    </Col>
                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-piggy-bank"></i>
                                        {loc`Finances`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <FormGroup>
                                          <Form.Label>{loc`Your net monthly income`}</Form.Label>
                                          <Row noGutters>
                                            <Col xs={6} sm={6}>
                                              <TextField
                                                  noFormGroup
                                                  name="customer.netMonthlyIncome"
                                                  placeholder="€"
                                                  hasServerErrors={hasServerErrors}
                                              />
                                            </Col>
                                            <Col xs={6} sm={6}>
                                              <SelectField
                                                  noFormGroup
                                                  name="customer.numberMonthsIncome"
                                                  options={[
                                                    {
                                                      value: 12,
                                                      label: loc`over 12 months`,
                                                    },
                                                    {
                                                      value: 13,
                                                      label: loc`over 13 months`,
                                                    },
                                                    {
                                                      value: 14,
                                                      label: loc`over 14 months`,
                                                    },
                                                    {
                                                      value: 15,
                                                      label: loc`over 15 months`,
                                                    },
                                                    {
                                                      value: 16,
                                                      label: loc`over 16 months`,
                                                    },
                                                  ]}
                                                  hasServerErrors={hasServerErrors}
                                              />
                                            </Col>
                                          </Row>
                                        </FormGroup>
                                        <FormGroup>
                                          <Form.Label>{loc`Your other monthly income`}</Form.Label>
                                          <Row noGutters>
                                            <Col lg={10} xs={10}>
                                              <TextField
                                                  noFormGroup
                                                  name="customer.otherIncome"
                                                  placeholder="€"
                                                  hasServerErrors={hasServerErrors}
                                              />
                                            </Col>
                                            <Col lg={2} xs={2}>
                                              <OverlayTrigger
                                                  key="left"
                                                  placement="left"
                                                  overlay={
                                                    <Tooltip>{loc`Property income or alimony paid`}</Tooltip>
                                                  }
                                              >
                                                <Button variant="dark">
                                                  <i className="fas fa-question"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </Col>
                                          </Row>
                                        </FormGroup>
                                        <FormGroup>
                                          <Form.Label>{loc`Your share of monthly charges`}</Form.Label>
                                          <Row noGutters>
                                            <Col lg={10} xs={10}>
                                              <TextField
                                                  noFormGroup
                                                  name="customer.rent"
                                                  placeholder="€"
                                                  hasServerErrors={hasServerErrors}
                                              />
                                            </Col>
                                            <Col lg={2} xs={2}>
                                              <OverlayTrigger
                                                  key="left"
                                                  placement="left"
                                                  overlay={
                                                    <Tooltip>{loc`Rent or mortgage`}</Tooltip>
                                                  }
                                              >
                                                <Button variant="dark">
                                                  <i className="fas fa-question"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </Col>
                                          </Row>
                                        </FormGroup>
                                        <FormGroup>
                                          <Form.Label>{loc`Your other monthly charges`}</Form.Label>
                                          <Row noGutters>
                                            <Col lg={10} xs={10}>
                                              <TextField
                                                  noFormGroup
                                                  name="customer.otherCharges"
                                                  placeholder="€"
                                                  hasServerErrors={hasServerErrors}
                                              />
                                            </Col>
                                            <Col lg={2} xs={2}>
                                              <OverlayTrigger
                                                  key="left"
                                                  placement="left"
                                                  overlay={
                                                    <Tooltip>{loc`Credit (excluding mortgage) and / or alimony paid`}</Tooltip>
                                                  }
                                              >
                                                <Button variant="dark">
                                                  <i className="fas fa-question"></i>
                                                </Button>
                                              </OverlayTrigger>
                                            </Col>
                                          </Row>
                                        </FormGroup>
                                      </div>
                                    </Col>
                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-home"></i> {loc`Housing`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <SelectField
                                            name="customer.address.housingType"
                                            label={loc`Housing`}
                                            options={this.getHousingTypes()}
                                            hasServerErrors={hasServerErrors}
                                        />
                                        <DateField
                                            name="customer.address.residenceDate"
                                            label={loc`Since...`}
                                            placeholder={loc`YYYY`}
                                            dateFormat={YEAR_FORMAT}
                                            dateFormatServer={loc`YYYY-MM-DD`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.address.residenceDate}
                                        />
                                        <SelectField
                                            name="customer.maritalStatus"
                                            label={loc`Family situation`}
                                            options={this.getFamilySituations()}
                                            hasServerErrors={hasServerErrors}
                                        />
                                        <TextField
                                            name="customer.dependentChildrenNumber"
                                            label={loc`Number of dependent children`}
                                            hasServerErrors={hasServerErrors}
                                            trimOnBlur={true}
                                        />
                                      </div>
                                    </Col>
                                    <Col lg={3} sm={12} md={6}>
                                      <h3 className="text-center margin-0">
                                        <i className="fas fa-money-check"></i>{" "}
                                        {loc`Bank account`}
                                      </h3>
                                      <div className="data-inner-block">
                                        <TextField
                                            name="customer.bankAccountDTO.iban"
                                            label={loc`IBAN`}
                                            hasServerErrors={hasServerErrors}
                                            trimOnBlur={true}
                                        />
                                        <TextField
                                            name="customer.bankAccountDTO.bic"
                                        label={loc`BIC or SWIFT`}
                                            hasServerErrors={hasServerErrors}
                                            trimOnBlur={true}
                                        />
                                        <DateField
                                            name="customer.yearBankSeniority"
                                            label={loc`Since...`}
                                            placeholder={loc`YYYY`}
                                            dateFormat={YEAR_FORMAT}
                                            dateFormatServer={loc`YYYY-MM-DD`}
                                            hasServerErrors={hasServerErrors}
                                            initialValue={customer.yearBankSeniority}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col lg={2} md={3} sm={12}>
                                  <Button
                                      variant="primary"
                                      type="submit"
                                      className="btn-solid btn"
                                      disabled={this.state?.submitButtonLocked === true}
                                  >
                                    {loc`Validate`}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                        )
                      }}
                  />
                </div>
                <footer>
                  <Row>
                    <Col>
                      <p>{loc`Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °433 911 799.`}</p>
                      <p>
                        {loc`The information requested is for the management of your leasing request. Unless otherwise stated, they are mandatory and in the absence of a complete response, your request may be refused. If you choose not to let us know, we will not be able to process your request. They are intended for the Lessor, Personal Finance Location, data controller. Through it, you will be able to receive proposals from partners. For more information on the processing of your personal data by Personal Finance Location and on your rights (right of access, rectification, erasure, limitation of processing, data portability, right of opposition and right to organize the management of your data after your death) you can refer to`}
                        <a href="https://static-evollis.evollis.com/pdf/clause_donnees_personnelles.pdf"target="_blank">{loc`the information notice on the use of personal data`}</a>
                        {loc`or contact us at the following address at Personal Finance Location - Customer Service - 62061 Cedex 9 - `}
                        <a href="mailto:consommateurs.donneespersonnelles@cetelem.fr">{loc`consommateurs.donneespersonnelles@cetelem.fr`}</a>
                      </p>
                      <p>{loc`You can object to receiving commercial prospecting by telephone by registering for free on the list of opposition to telephone canvassing on the website www.bloctel.gouv.fr or by post to - Société Opposetel, Service Bloctel, 6 rue Nicolas Siret 10000 Troyes. This registration prohibits a professional from canvassing you by telephone, unless this solicitation occurs in the context of the execution of a current contract and it has a connection with the subject of this contract.`}</p>
                      <p>{loc`The evaluation of your file is subject to automated processing, which may result in the refusal of your subscription request.You have the right to obtain human intervention, to express your point of view and to contest the decision by contacting us at the following address: Personal Finance Location - 62061 Arras - Cedex 9 or by email: serviceclientspflocation @bnpparibas -pf.com`}</p>
                      <p>{loc`Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125 71 - ORIAS number: 13003909.`}</p>
                    </Col>
                  </Row>
                </footer>
              </Col>
            </Row>
          </Container>
        </>
    );
  }

  loadCustomerDataRelyingOnComponentContext() {
    if (this.props.tunnelData != null && this.props.tunnelData.comeBackToClientStep === true) {
      return this.props.tunnelData.data.customer;
    }
    return this.props.tunnelData.data.order.customer;
  }

  loadPersonDataRelyingOnComponentContext() {
    if (this.props.tunnelData != null && this.props.tunnelData.comeBackToClientStep === true) {
      return this.props.tunnelData.data.person;
    }
    return this.props.tunnelData.data.order.customer.person;
  }
}

export default Client3Page;
