import React, { Component } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import defaultLogo from "../asset/img/logo.jpg";
import * as FinalForm from "react-final-form";
import TextField from "../form/TextField";
import RadiosField from "../form/RadiosField";
import DateField from "../form/DateField";
import SelectField from "../form/SelectField";
import { deepSet, deepGet } from "../core/utils.common";
const emptyTunnelData = { data: {} };
const DATE_FORMAT = "DD/MM/YYYY";

class Client1Page extends Component {
  onSubmit = async values => {
    const submitErrors = await this.props.onSetStep(values);
    console.log(submitErrors);
    return submitErrors;
  };

  render() {
    const { removeTunnelDataError, tunnelData = emptyTunnelData } = this.props;
    const hasServerErrors = !!tunnelData.errors;
    return (
      <>
        {/* ————— TOPBAR ————— */}
        <header>
          <nav className="text-center">
            <Row className="justify-content-center no-gutters">
              <Col xs={5} md={3} lg={2}>
                <a href="https://www.uzit-direct.com/" title="UZ'it">
                  <img src={defaultLogo} alt="UZ'it" />
                </a>
              </Col>
            </Row>
          </nav>
        </header>
        <Row className="justify-content-center no-gutters">
          <Col sm={12}>
            <ul className="breadcrumb">
              <li>My contract choices</li>
              <li>My addresses</li>
              <li>Upfront</li>
              <li className="active">My contract</li>
              <li>Finalization</li>
            </ul>
          </Col>
        </Row>

        {/* ————— CONTENT ————— */}
        <Container>
          <Row>
            <Col lg={12} sm={12}>
              <h1>My contract</h1>
              <div className="data">
                <h4>
                  <span className="step">Step 1</span>
                </h4>
                <p className="eligibility">
                  The information below is necessary for our renter for the constitution of your rental file.
                  <br />
                  To meet our eligibility conditions, you must have regular activity income and not have any current
                  payment incidents.
                </p>
                <FinalForm.Form
                  initialValues={tunnelData.data}
                  validate={values => {
                    // server side errors
                    const { tunnelData = {} } = this.props;
                    let errors = {};
                    if (!tunnelData.errors) {
                      return;
                    }
                    tunnelData.errors.forEach(error => {
                      const valueNotChanged = deepGet(tunnelData.data, error.path) === deepGet(values, error.path);
                      if (valueNotChanged) {
                        deepSet(errors, error.path, error.message);
                      } else {
                        removeTunnelDataError(error.path);
                      }
                    });
                    return errors;
                  }}
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row className="justify-content-center">
                        <Col lg={3} sm={12}>
                          <RadiosField
                            name="person.title"
                            items={[
                              { name: "MR", label: "Mr" },
                              { name: "MS", label: "Ms" },
                              { name: "MRS", label: "Mrs" }
                            ]}
                            label="Title"
                            hasServerErrors={hasServerErrors}
                          />
                          <TextField required name="person.name" label="Last name" hasServerErrors={hasServerErrors} />
                          <TextField name="person.maiden.name" label="Maiden name" hasServerErrors={hasServerErrors} />
                          <TextField name="person.firstName" label="First name" hasServerErrors={hasServerErrors} />
                          <TextField
                            name="customer.address.mobilePhone"
                            label="Mobile"
                            hasServerErrors={hasServerErrors}
                          />
                        </Col>
                        <Col lg={3} sm={12}>
                          <TextField
                            name="customer.address.address"
                            label="Address"
                            hasServerErrors={hasServerErrors}
                          />
                          <TextField
                            name="customer.address.additionalAddress"
                            label="Address 2"
                            hasServerErrors={hasServerErrors}
                          />
                          <TextField
                            name="customer.address.locality"
                            label="Locality"
                            hasServerErrors={hasServerErrors}
                          />
                          <TextField name="customer.address.postalCode" label="Zip" hasServerErrors={hasServerErrors} />
                          <TextField name="customer.address.city" label="City" hasServerErrors={hasServerErrors} />
                        </Col>
                        <Col lg={3} sm={12}>
                          <DateField
                            name="person.birthDate"
                            label="Birthdate"
                            dateFormat={DATE_FORMAT}
                            dateFormatServer={"YYYY-MM-DD"}
                            hasServerErrors={hasServerErrors}
                          />
                          <SelectField
                            disabled
                            name="person.birthDepartmentCode"
                            label="Department of birth"
                            options={[
                              { value: "83", label: "Var" },
                              { value: "33", label: "Gironde" },
                              { value: "11", label: "Aude" }
                            ]}
                            hasServerErrors={hasServerErrors}
                          />
                          <TextField
                            disabled
                            name="person.birthCity"
                            label="City of birth"
                            hasServerErrors={hasServerErrors}
                          />
                          <SelectField
                            disabled
                            name="person.birthCountryCode"
                            label="Country of birth"
                            options={[
                              { value: "fr", label: "France" },
                              { value: "ch", label: "China" },
                              { value: "us", label: "United-States" }
                            ]}
                            hasServerErrors={hasServerErrors}
                          />
                          <SelectField
                            disabled
                            name="person.citizenshipCountryCode"
                            label="Citizenship"
                            options={[
                              { value: "fr", label: "French" },
                              { value: "ch", label: "Chinese" },
                              { value: "us", label: "American" }
                            ]}
                            hasServerErrors={hasServerErrors}
                          />
                        </Col>
                      </Row>

                      <Row className="justify-content-center">
                        <Col lg={3} md={5} sm={12}>
                          <Button variant="primary" type="submit" className="btn-solid btn">
                            Validate and go to step 2
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                />
              </div>
              <footer>
                <Row>
                  <Col>
                    <p>
                      Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance
                      Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU
                      with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °
                      433 911 799.
                    </p>
                    <p>
                      The information requested is for the management of your leasing request. Unless otherwise stated,
                      they are mandatory and in the absence of a complete response, your request may be refused. If you
                      choose not to let us know, we will not be able to process your request. They are intended for the
                      Lessor, Personal Finance Location, data controller. Through it, you will be able to receive
                      proposals from partners. For more information on the processing of your personal data by Personal
                      Finance Location and on your rights (right of access, rectification, erasure, limitation of
                      processing, data portability, right of opposition and right to organize the management of your
                      data after your death) you can refer to{" "}
                      <a href="informationnotice.html">the information notice on the use of personal data</a> or contact
                      us at the following address at Personal Finance Location - Customer Service - 62061 Cedex 9 -
                      <a href="mailto:consommateurs.donneespersonnelles@cetelem.fr">
                        consommateurs.donneespersonnelles@cetelem.fr
                      </a>
                    </p>
                    <p>
                      The information requested is for the management and evaluation of your Service Contract. Unless
                      otherwise stated, they are mandatory and in the absence of a complete response, your request may
                      be refused. If you choose not to let us know, we will not be able to process your request. They
                      are intended for EVOLLIS, responsible for the processing. Through it, you will be able to receive
                      proposals from partners. For more information on the processing of your personal data by EVOLLIS
                      and on your rights (right of access, rectification, erasure, limitation of processing, data
                      portability, right of opposition and right to organize the management of your data after your
                      death) you can refer <a href="datausage.html">here to the use of your data</a> or contact us at
                      the following address EVOLLIS - Customer Service - 22-26 quai de Bacalan - 33300 Bordeaux or at
                      <a href="mailto:protectiondonnees@evollis.com">protectiondonnees@evollis.com</a>
                    </p>
                    <p>
                      Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de
                      Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125
                      71 - ORIAS number: 13003909.
                    </p>
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Client1Page;
