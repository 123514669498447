import React, { Component } from "react";
import { AccessKeysContext } from "../context/AccessKeysContext";
import { Route } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { LoadingContext } from "../context/LoadingContext";

export default class RouteWithAccessKeys extends Component {
  state = {
    accessKeys: {},
    setAccessKeys: accessKeys => this.setState({ accessKeys })
  };

  render() {
    const { path, render } = this.props;
    return (
      <LoadingContext.Consumer>
        {({ loading }) => {
          const isLoading = loading !== 0;
          return (
            <>
              {isLoading && (
                <div className="spinner-container">
                  <Spinner className="spinner" animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
              {/*!isLoading && */<Route path={path} render={render} />}
            </>
          );
        }}
      </LoadingContext.Consumer>
    );
  }
}
