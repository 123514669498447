import { makeValidate } from "../../form/makeValidate";
import { loc } from "../../core/utils";
import moment from "moment";
import * as yup from "yup";

// error messages
const requiredMessage = () => loc`Required`;
const userMustAssertToBeCardOwnerMessage = () => loc`Please assert you're the real card owner`;
const monthMustBeBeetweenOneAndTwelve = () => loc`Not a valid MM value`;
const yearFormatMessage = () => loc`Not a valid YYYY date`;
const yearMustBeAtLeastCurrentYearMessage = () => loc`Can't be before current year`;
const cardIsExpired = () => loc`Your credit card is expired`;
const mustBeComposedOfThreeDigitsMessage = () => loc`Must be composed of 3 digits`;
const twelveToNineteenDigitsMessage = () => loc`Must be composed of 12-19 digits`;
const threeDigitsPattern = "^[0-9]{3,3}$";
const twelveToNineteenDigitsPattern = "^[0-9]{12,19}$";
const yyyyDateFormatPattern = "^[0-9]{4}$";
const momentDDMMYYYYFormat = 'DD/MM/YYYY';


// todo : refactor in helpers redundant test

const schema = yup.object().shape({
  userIsRealCardOwner : yup
  .boolean()
  .required()
  .oneOf([true], userMustAssertToBeCardOwnerMessage),
  month : yup.string().required(requiredMessage).oneOf(["01","02","03","04","05","06","07","08","09","10","11","12"], monthMustBeBeetweenOneAndTwelve),
  year : yup.string().test({
    name : "cbYearVerification",
    message : "",
    test(value) {
      if (!value) {
        return this.createError({
          message: requiredMessage,
          path: "year",
        });
      }
      if (!value.match(yyyyDateFormatPattern)) {
        return this.createError({
          message: yearFormatMessage,
          path: "year",
        });
      }
      const monthString = this.options.values?.month;
      const momentFromMonthAndYear = moment("01/" + monthString + "/" + value, momentDDMMYYYYFormat);
      const now = moment();
      const momentFromMonthAndYearIsValid = momentFromMonthAndYear.isValid();
      const momentFromMonthAndYearMonth = momentFromMonthAndYear.month();
      const nowMonth = now.month();
      const monthIsBeforeCurrentMonthOrEqual =  momentFromMonthAndYearMonth <= nowMonth;
      const yearIsCurrentYear = momentFromMonthAndYear.year() === now.year();
      const yearIsPast = momentFromMonthAndYear.year() < now.year();
      if (momentFromMonthAndYear && now && momentFromMonthAndYearIsValid && ((monthIsBeforeCurrentMonthOrEqual && yearIsCurrentYear) || (yearIsPast))) {
        return this.createError({
          message: cardIsExpired,
          path: "year",
        });
      }
      if(!(moment(value).year() - moment().year() >= 0)) {
        return this.createError({
          message: yearMustBeAtLeastCurrentYearMessage,
          path: "year",
        });
      }
    }
  }),
  cvv : yup.string().test({
    name : "cvvTest",
    message : "",
    test(value){
      if (!value) {
        return this.createError({
          message: requiredMessage,
          path: "cvv",
        });
      }
      if (!value.match(threeDigitsPattern)) {
        return this.createError({
          message: mustBeComposedOfThreeDigitsMessage,
          path: "cvv",
        });
      }
    }
  }),
  cardNumber : yup.string().nullable().test({
    name : "cardNumberTest",
    message : "",
    test(value){
      if (!value) {
        return this.createError({
          message: requiredMessage,
          path: "cardNumber",
        });
      }
      let testedValue = (' ' + value).slice(1);
      testedValue = testedValue.replace(/\s/g, '');
      if (!testedValue.match(twelveToNineteenDigitsPattern)) {
        return this.createError({
          message: twelveToNineteenDigitsMessage,
          path: "cardNumber",
        });
      }
    }
  }),
});
const validate = makeValidate(schema);
export default validate;