import React, { Component } from "react";
import { listsService } from "../core/utils";

class Welcome extends Component {
  state: {};

  componentDidMount() {
    listsService.getList("country", () => this.setState({ countryListLoaded: true }));
  }

  render() {
    let quotation = this.props.contract.quotations[0];
    let paymentPlan = quotation.simulation.paymentPlan;
    let client = this.props.client;
    let address = client.addresses[0];

    return (
      <div className="modal-financing">
        <h5>Me</h5>
        <p>
          {client.firstName} {client.lastName}
        </p>
        <p>
          {address.address1}, {address.zipcode} {address.city} {address.state} (
          {listsService.getLabel("country", address.country)})
        </p>
        <p>{client.phone}</p>
        <p>{client.email}</p>
        <br />
        <div>
          <span className="payment-schedule-total">{quotation.simulation.formattedCurrency}</span>
          <h5>My purchase</h5>
          <p>Date: {quotation.simulation.formattedDate}</p>
        </div>
        <br />
        <h5>Pay in several times</h5>
        <div className="payment-schedule">
          {paymentPlan &&
            paymentPlan.map((it, key) => (
              <div key={key} className="payment-schedule-line">
                <div key={1} className="payment-schedule-bullet">
                  {key === 0 && <i className="fa fa-heart" />}
                  {key !== 0 && <i className="fa fa-circle" />}
                  {key < paymentPlan.length - 1 && <span className="payment-schedule-vertical-line" />}
                </div>
                <div key={2}>
                  {it.wording}
                  <span className="payment-schedule-payment">{it.payment}</span>
                </div>
              </div>
            ))}
        </div>
        <br />
        <br />
        <div className="financing-button-div">
          <button type="button" className="btn-solid btn" onClick={() => this.props.onSetStep(+1)}>
            I pay in several times
          </button>
        </div>
      </div>
    );
  }
}

export default Welcome;
