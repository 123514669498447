import {loc} from "./utils";

export var fr_FR = {
  /* ========================================== */
  /* COMMON =================================== */
  /* ========================================== */
  "Internal error" : "Erreur interne",
  "The server was unable to process your request." : "Le serveur n'a pas pu traiter votre requête.",
  "My contract choices": "Mes choix de contrats",
  "My addresses": "Mes adresses",
  "My contract": "Mon contrat",
  "Finalization": "Confirmation",
  "Back to shop":"Retour au site",
  "Validate":"Valider",
  "Upfront":"1er versement",
  "My basket":"Mon panier",
  "Contract duration":"Durée du contrat",
  "months": "mois",
  "Offered": "Offerts",
  "Shipping fees":"Frais de livraison",
  "My credit card data":"Mes données carte bancaire",
  "Monthly payment":"Loyer mensuel",
  "/month":"/mois",
  "YYYY":"AAAA",
  "YYYY/MM/DD":"JJ/MM/AAAA",
  "Select...":"Sélectionner...",
  "Services included":"Services inclus",
  "Full warranty":"Garantie complète",
  "Upgrade":"Renouvellement",
  "including services pack at":"dont pack de services à",
  "Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °433 911 799." : "Contrat de location longue durée, sous réserve d’acceptation du dossier par le Loueur Personal Finance Location et après expiration du délai légal de rétractation. Personal Finance Location - SASU au capital de 1 500 000 € - Siège social : 1 boulevard Haussmann - 75009 Paris – RCS Paris N° 433 911 799." ,
  "The information requested is for the management of your leasing request. Unless otherwise stated, they are mandatory and in the absence of a complete response, your request may be refused. If you choose not to let us know, we will not be able to process your request. They are intended for the Lessor, Personal Finance Location, data controller. Through it, you will be able to receive proposals from partners. For more information on the processing of your personal data by Personal Finance Location and on your rights (right of access, rectification, erasure, limitation of processing, data portability, right of opposition and right to organize the management of your data after your death) you can refer to":"Les informations demandées ont pour finalité la gestion de votre demande de location. Sauf mention contraire, elles sont obligatoires et à défaut de réponse complète, votre demande pourra être refusée. Si vous choisissez de ne pas nous les communiquer, nous ne pourrons pas traiter votre demande. Elles sont destinées au Loueur, Personal Finance Location, responsable de traitement. Par son intermédiaire, vous pourrez recevoir des propositions émanant de partenaires. Pour plus d’informations sur le traitement de vos données personnelles par Personal Finance Location et sur vos droits (droit d’accès, de rectification, à l’effacement, à la limitation du traitement, à la portabilité des données, droit d’opposition et droit d’organiser la gestion de vos données après votre décès) vous pouvez vous reporter à ",
  "the information notice on the use of personal data":"la notice d’information sur l’utilisation des données personnelles",
  "or contact us at the following address at Personal Finance Location - Customer Service - 62061 Cedex 9 - ":"ou nous contacter à l’adresse suivante à Personal Finance Location – Service Clients – 62061 Cedex 9 - ",
  "consommateurs.donneespersonnelles@cetelem.fr":"consommateurs.donneespersonnelles@cetelem.fr",
  "The information requested is for the management and evaluation of your Service Contract. Unless otherwise stated, they are mandatory and in the absence of a complete response, your request may be refused. If you choose not to let us know, we will not be able to process your request. They are intended for EVOLLIS, responsible for the processing. Through it, you will be able to receive proposals from partners. For more information on the processing of your personal data by EVOLLIS and on your rights (right of access, rectification, erasure, limitation of processing, data portability, right of opposition and right to organize the management of your data after your death) you can refer ":"Les informations demandées ont pour finalité la gestion et l’évaluation de votre Contrat de services. Sauf mention contraire, elles sont obligatoires et à défaut de réponse complète, votre demande pourra être refusée. Si vous choisissez de ne pas nous les communiquer, nous ne pourrons pas traiter votre demande. Elles sont destinées à EVOLLIS, responsable du traitement. Par son intermédiaire, vous pourrez recevoir des propositions émanant de partenaires. Pour plus d’informations sur le traitement de vos données personnelles par EVOLLIS et sur vos droits (droit d’accès, de rectification, à l’effacement, à la limitation du traitement, à la portabilité des données, droit d’opposition et droit d’organiser la gestion de vos données après votre décès) vous pouvez vous reporter ",
  "here to the use of your data":" ici sur l’utilisation de vos données",
  "or contact us at the following address EVOLLIS - Customer Service - 22-26 quai de Bacalan - 33300 Bordeaux or at ":"ou nous contacter à l’adresse suivante EVOLLIS – Service Clients – 43 Cours d’Albret – 33000 Bordeaux ou à l’adresse ",
  "protectiondonnees@evollis.com":"protectiondonnees@evollis.com",
  "Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125 71 - ORIAS number: 13003909.": "Contrat de services géré par EVOLLIS – SAS au capital de 1 277 010 €- Siège social : 22-26 Quai de Bacalan – 33300 Bordeaux – RCS Bordeaux N° 530 612 571 - N° TVA intracommunautaire : FR 195 306 125 71 – N° ORIAS : 13003909.",
  "You can object to receiving commercial prospecting by telephone by registering for free on the list of opposition to telephone canvassing on the website www.bloctel.gouv.fr or by post to - Société Opposetel, Service Bloctel, 6 rue Nicolas Siret 10000 Troyes. This registration prohibits a professional from canvassing you by telephone, unless this solicitation occurs in the context of the execution of a current contract and it has a connection with the subject of this contract.":"Il vous est possible de vous opposer à recevoir de la prospection commerciale par voie téléphonique en vous inscrivant gratuitement sur la liste d’opposition au démarchage téléphonique sur le site internet www.bloctel.gouv.fr ou par courrier postal à - Société Opposetel, Service Bloctel, 6 rue Nicolas Siret 10000 Troyes. Cette inscription interdit à un professionnel de vous démarcher téléphoniquement, sauf si cette sollicitation intervient dans le cadre de l'exécution d'un contrat en cours et qu’elle a un rapport avec l'objet de ce contrat.",
  "The evaluation of your file is subject to automated processing, which may result in the refusal of your subscription request.You have the right to obtain human intervention, to express your point of view and to contest the decision by contacting us at the following address: Personal Finance Location - 62061 Arras - Cedex 9 or by email: serviceclientspflocation @bnpparibas -pf.com":"L’évaluation de votre dossier fait l’objet d’un traitement automatisé susceptible d’entrainer un refus de votre de demande de souscription. Vous disposez d’un droit d’obtenir une intervention humaine, d’exprimer votre point de vue et de contester la décision en nous contactant à l’adresse suivante : Personal Finance Location – 62061 Arras - Cedex 9 ou par email : serviceclientspflocation@bnpparibas-pf.com",
  /* ========================================== */
  /* ERROR PAGES ============================= */
  /* ========================================== */
  "The server has encountered an error, and couldn't treat your request.":"Le serveur a rencontré une erreur et n’a pas été en mesure de traiter votre requête.",
  "Page not found":"Page introuvable",
  "The page you're asking for doesn't exist or had been deleted":"La page que vous recherchez n’existe pas ou a été supprimée.",
  "Service unavailable":"Service indisponible",
  "Service is unavailable, please come back later.":"Le service est temporairement indisponible. Merci de revenir plus tard.",
  /* ========================================== */
  /* UPFRONT PAGE ============================= */
  /* ========================================== */
  "This step only constitutes a request for authorization of payment. ": "Cette étape ne constitue qu’une demande d’autorisation de paiement. ",
  "The first payment of ":"Le 1er versement de ",
  "by credit card will only be effective after signing your rental contract and downloading your supporting documents. ": "par carte bancaire ne sera effectif qu’après signature de votre contrat de location et téléchargement de vos justificatifs. ",
  "If your rental request is subsequently refused by the lessor following its study, then this amount will be immediately refunded.": "Si votre demande de location est par la suite refusée par le loueur à la suite de son étude, alors ce montant sera immédiatement remboursé.",
  "Cards not accepted":"Cartes non-acceptées",
  "Systematically authorized cards (such as Electron, Maestro, prepaid, rechargeable), e-CB and American Express." : "Cartes à autorisation systématique (de type Electron, Maestro, prépayées, rechargeables), e-CB et American Express.",
  "Upfront amount of your leasing contract": "Montant du 1er versement de votre contrat de location",
  "Card number": "Numéro de carte",
  "Checkout and upfront" : "Provision",
  "MM":"MM",
  "CB":"CB",
  "Visa":"Visa",
  "Mastercard":"Mastercard",
  "Your credit card is expired":"Votre carte est expirée",
  "Expiration date":"Date d'expiration",
  "CVV":"CVV",
  "I, undersigned $ certify that I am the holder of the bank card used for the request for payment of my 1st payment.": ["Je soussigné(e) ", " certifie être le titulaire de la carte bancaire utilisée pour la demande de paiement de mon 1er versement."],
  "Pay":"Payer",
  "Validate the payment authorization":"Valider l'autorisation de paiement",
  "If you benefit from a promo code on a « Refunded upfront » operation, you will receive a refund check for your 1st payment from the company Evollis, publisher of the uzit-direct.com within a maximum of 30 days following the final validation of your rental request.":"Si vous bénéficiez d'un code promo sur une opération « 1er versement remboursé », vous recevrez un chèque de remboursement de votre 1er versement de la part de la société Evollis, éditeur du site uzit-direct.com dans un délai maximum de 30 jours suivant la validation définitive de votre demande de location.",
  "Your card has been declined. Please check the accuracy of the information entered. You can also contact your bank to verify that it is not blocking payment.":"Votre carte a été refusée. Merci de vérifier l'exactitude des informations saisies. Vous pouvez également contacter votre banque pour vérifier qu'elle ne bloque pas le paiement.",
  /* ========================================== */
  /* CLIENT DATA PAGE ========================= */
  /* ========================================== */
  "Bank Seniority" : "Ancienneté bancaire",
  "The information below is necessary for our renter for the constitution of your rental file.":"Les informations ci-dessous sont nécessaires à notre loueur pour la constitution de votre dossier de location.",
  "To meet our eligibility conditions, you must have regular activity income and not have any current payment incidents.":"Pour remplir nos conditions d’éligibilité, vous devez bénéficier d’un revenu d’activité régulier et ne pas avoir d’incidents de paiement en cours.",
  "Identity": "Identité",
  "Title": "Civilité",
  "Mr": "M.",
  "Ms": "Mme",
  "Mrs": "Mlle",
  "Last name":"Nom",
  "Maiden name":"Nom de jeune fille",
  "First name":"Prénom",
  "Address":"Adresse",
  "Address 2":"Cplt. d'adresse",
  "Locality":"Lieu dit",
  "Zip":"Code postal",
  "City":"Ville",
  "Birth":"Naissance",
  "Birthdate":"Date de naissance",
  "Department of birth":"Dpt. de naissance",
  "City of birth":"Ville de naissance",
  "Country of birth":"Pays de naissance",
  "Citizenship":"Nationalité",
  "Contact":"Contact",
  "Mobile":"Portable",
  "Email":"Email",
  "Occupation":"Profession",
  "Since...":"Depuis...",
  "Finances":"Finances",
  "Your net monthly income":"Vos revenus mensuels nets",
  "over 12 months":"sur 12 mois",
  "over 13 months":"sur 13 mois",
  "over 14 months":"sur 14 mois",
  "over 15 months":"sur 15 mois",
  "over 16 months":"sur 16 mois",
  "Your other monthly income":"Vos autres revenus mensuels",
  "Property income or alimony paid":"Revenus fonciers ou pension alimentaire reçue",
  "Your share of monthly charges":"Votre part de charges mensuelles",
  "Rent or mortgage":"Loyer ou crédit immobilier",
  "Your other monthly charges":"Vos autres charges mensuelles",
  "Credit (excluding mortgage) and / or alimony paid":"Crédit (hors prêt immobilier) et/ou pension alimentaire versée",
  "Housing":"Logement",
  "Family situation":"Situation familiale",
  "Number of dependent children":"Nb. d'enfants",
  "Bank account":"Compte bancaire",
  "IBAN":"IBAN",
  "BIC or SWIFT":"BIC ou SWIFT",
  /* ========================================== */
  /* SUMMARY ================================== */
  /* ========================================== */
  "Summary of your data and your order":"Récapitulatif de vos données et de votre commande",
  "within":"sur",
  "Edit my data":"Modifier mes données",
  "I agree to receive electronically commercial offers from companies in the BNP Paribas Personal Finance group.":"J’accepte de recevoir par voie électronique des offres commerciales de la part des sociétés du groupe BNP Paribas Personal Finance.",
  "I oppose the receipt of commercial offers by post or telephone from BNP Paribas Personal Finance group companies.":"Je m'oppose à la réception d'offres commerciales par voie postale ou téléphonique de la part des sociétés de groupe BNP Paribas Personal Finance.",
  /* ========================================== */
  /* NETHEOS ================================== */
  /* ========================================== */
  "Your product will be delivered to you after the final acceptance of the long-term rental contract and upon receipt of supporting documents by the rental company.":"Votre produit vous sera livré après l’acceptation définitive du contrat de location longue durée et à réception des pièces justificatives par le loueur.",
  /* ========================================== */
  /* CONFIRMATION ============================= */
  /* ========================================== */
  "You request has been sent !":"Votre demande a bien été envoyée !",
  "Your file number is":"Votre numéro de dossier est le",
  "Now, we need to review it and determine if you are eligible for our offer. A response will be provided to you within 48 hours.":"Nous devons maintenant l’examiner et déterminer si vous êtes éligible à notre offre. Une réponse vous sera apportée dans un délai de 48h.",
  "Thank you for your trust and see you soon on UZ’it !":"Merci de votre confiance et à bientôt sur UZ’it !",
  /* ========================================== */
  /* OK KO BACK =============================== */
  /* ========================================== */
  "Your request is already finalized":"Votre demande est déjà finalisée",
  "You will receive a final response within 24 hours.":"Votre recevrez une réponse définitive sous 24h.",
  "The UZ'it team thanks you for your trust.":"L'équipe UZ'it Direct vous remercie de votre confiance !",
  "Your request has already been validated":"Votre demande a déjà été validée",
  "The information you have entered can no longer be changed.":"Les informations que vous avez saisies ne peuvent plus être modifiées.",
  "Please click on the button below to finalize your order.":"Merci de cliquer sur le bouton ci dessous pour finaliser votre commande.",
  "Finalize your order":"Finalisez votre commande",
  "Your card has been refused":"Votre carte bancaire a été refusée",
  "Your order is canceled. The amount of the upfront will not be deducted from you.":"Votre commande est annulée. Le montant de l'apport ne vous sera pas prélevé.",
  "The UZ'it team thanks you for your request.":"L'équipe UZ'it Direct vous remercie de votre demande.",
  "Your request has been refused":"Votre demande est refusée",
  "We regret to inform you that we cannot give a favorable response to your request.":"Nous sommes au regret de vous informer que nous ne pouvons pas donner une suite favorable à votre demande.",
  "Maintenance":"Maintenance",
  "The app is temporarily unavailable.":"L'application est temporairement indisponible.",
  "Please come back later.":"Merci de revenir plus tard.",
  /* ========================================== */
  /* VALIDATION =============================== */
  /* ========================================== */
  "Not a valid IBAN" : "IBAN invalide",
  "Not a valid BIC" : "BIC invalide",
  "Required": "Obligatoire",
  "Date is not valid":"Date invalide",
  "maximum characters": "caractères maximum",
  "Unauthorized characters": "Caractères non-autorisés",
  "Phone number must start with 06 or 07 and contain 10 numbers": "Le numéro de téléphone doit commencer par 06 ou 07 et contenir 10 numéros",
  "Invalid postal code": "Code postal invalide",
  "Minimum age must be > 18 years old": "L'âge minimum doit être > 18 ans",
  "Maximum age must be < 110 years old": "L'âge maximum doit être < 110 ans",
  "Must be positive": "Le nombre doit être positif",
  "minimum characters": "caractères minimum",
  "number of children must be contains between 0 and 10": "Le nombre d'enfants doit être compris entre 0 et 10",
  "birthDate is not valid": "Date de naissance invalide",
  "employmentDate cannot be before birthDate": "La date d'embauche ne peut pas être avant la date de naissance",
  "Year seniority bank cannot be before birth date" : "La date d'ancienneté bancaire ne peut pas être avant la date de naissance",
  "difference between employment date and birth date must be at least 16 years": "La différence entre la date d'embauche et de naissance doit être d'au moins 16 ans",
  "difference between bank seniority date and birth date must be at least 16 years" : "La différence entre la date d'ancienneté bancaire et la date de naissance doit être d'au moins 16 ans",
  "employmentDate cannot be in future": "La date d'embauche ne peut pas être dans le futur",
  "Must be a whole number" : "Doit être un nombre entier",
  "Must be a number" : "Doit être un nombre",
  "Email invalid" : "Adresse mail invalide",
  "Please assert you're the real card owner" : "Cochez pour confirmer être le propriétaire de cette carte de paiement",
  "Not a valid MM value" : "Mois au format MM requis",
  "Not a valid YYYY date" : "Année au format AAAA requise",
  "Can't be before current year" : "Ne peut pas être avant l'année actuelle",
  "Must be composed of 3 digits" : "Doit être composé de 3 chiffres",
  "Must be composed of 12-19 digits" : "Doit être composé de 12-19 chiffres",
  "Not a valid YYYY-MM-DD date" : "Entrez une date au format JJ/MM/AAAA",
  "Residence date cannot be before birth date" : "La date de résidence ne peut pas être avant la date de naissance",
  "This date cannot be in future" : "Cette date ne peut pas être dans le futur",
  "Employment date cannot be before birth date" : "La date d'embauche ne peut pas être avant la date de naissance",
  "Employment date cannot be in future" : "La date d'embauche ne peut pas être dans le futur",
  "Please pick a correct value" : "Choisissez une valeur correcte",
};
