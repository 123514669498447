import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import defaultLogo from "../asset/img/logo.jpg";
import { loc } from "../core/utils";

class ComeBackLaterPage extends Component {
  state: {};

  componentDidMount() {}

  render() {
    return (
      <>
        {/* ————— TOPBAR ————— */}
        <header>
          <nav className="text-center">
            <Row className="justify-content-center no-gutters">
              <Col xs={5} md={3} lg={2}>
                <a href="https://www.uzit-direct.com/" title="UZ'it">
                  <img src={defaultLogo} alt="UZ'it" />
                </a>
              </Col>
            </Row>
          </nav>
        </header>
        {/* ————— CONTENT ————— */}
        <Container>
          <div className="ok-ko">
            <Row className="justify-content-center">
              <Col sm={8}>
                <span className="ico-awating">
                  <i class="fal fa-clock"></i>
                </span>
                <h1>{loc`Demande en attente`}</h1>
                <p>{loc`Nous allons vous envoyer un email vous permettant de finaliser votre dossier plus tard.`}</p>
                <p>{loc`Sans vos pièces justificatives, votre dossier sera annulé dans un délai de 3 jours.`}</p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={3} sm={12}>
                <Button href="http://www.uzit-direct.com/" className="btn-solid btn">
                  {loc`Back to shop`}
                </Button>
              </Col>
            </Row>
          </div>
          <footer>
            <Row>
              <Col>
                <p>{loc`Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °433 911 799.`}</p>
                <p>
                  {loc`The information requested is for the management of your leasing request. Unless otherwise stated, they are mandatory and in the absence of a complete response, your request may be refused. If you choose not to let us know, we will not be able to process your request. They are intended for the Lessor, Personal Finance Location, data controller. Through it, you will be able to receive proposals from partners. For more information on the processing of your personal data by Personal Finance Location and on your rights (right of access, rectification, erasure, limitation of processing, data portability, right of opposition and right to organize the management of your data after your death) you can refer to`}
                  <a href="https://static-evollis.evollis.com/pdf/clause_donnees_personnelles.pdf" target="_blank">{loc`the information notice on the use of personal data`}</a>
                  {loc`or contact us at the following address at Personal Finance Location - Customer Service - 62061 Cedex 9 - `}
                  <a href="mailto:consommateurs.donneespersonnelles@cetelem.fr">{loc`consommateurs.donneespersonnelles@cetelem.fr`}</a>
                </p>
                <p>{loc`You can object to receiving commercial prospecting by telephone by registering for free on the list of opposition to telephone canvassing on the website www.bloctel.gouv.fr or by post to - Société Opposetel, Service Bloctel, 6 rue Nicolas Siret 10000 Troyes. This registration prohibits a professional from canvassing you by telephone, unless this solicitation occurs in the context of the execution of a current contract and it has a connection with the subject of this contract.`}</p>
                <p>{loc`The evaluation of your file is subject to automated processing, which may result in the refusal of your subscription request.You have the right to obtain human intervention, to express your point of view and to contest the decision by contacting us at the following address: Personal Finance Location - 62061 Arras - Cedex 9 or by email: serviceclientspflocation @bnpparibas -pf.com`}</p>
                <p>{loc`Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125 71 - ORIAS number: 13003909.`}</p>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default ComeBackLaterPage;
