import { set, get } from "lodash";

export function makeValidate(validator) {
  return async values => {
    try {
      await validator.validate(values, { abortEarly: false, values });
    } catch (err) {
      return err.inner.reduce((errors, { path, message }) => {
        if (errors.hasOwnProperty(path)) {
          set(errors, path, get(errors, path) + " " + message);
        } else {
          set(errors, path, message);
        }
        return errors;
      }, {});
    }
  };
}
