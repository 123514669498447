import React, { Component } from "react";

class FinalWelcome extends Component {
  state = {};

  componentDidMount() {}

  render() {
    let contract = this.props.contract;
    let quotation = contract.quotations[0];

    return (
      <div className="modal-financing">
        <div>
          <span className="payment-schedule-total">{quotation.simulation.formattedCurrency}</span>
          <h5>My purchase</h5>
          <p>Date: {quotation.simulation.formattedDate}</p>
        </div>
        <h5>Welcome !</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
        <div className="financing-button-div">
          <button type="button" className="btn-solid btn" onClick={this.onCloseSplitModal}>
            Back to the shop
          </button>
        </div>
        <div className="text-right">
          <button type="button" className="btn-simple" onClick={() => this.props.onSetStep(-1)}>
            back
          </button>
        </div>
      </div>
    );
  }
}

export default FinalWelcome;
