import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Welcome from "./Welcome";
import Consent from "./Consent";
import Bank from "./Bank";
import SignSepaMandate from "./SignSepaMandate";
import FinalWelcome from "./FinalWelcome";
import "./split.scss";
import { formatDate, roundCurrency, formatCurrency } from "../core/utils.common";

class PagesLayout extends Component {
  constructor(props) {
    super(props);

    // get data from iframe url
    let tunnelData = this.props.tunnelData;
    if (tunnelData) {
      try {
        // add payment plan in quotation simulation
        let total = tunnelData.contract.quotations[0].financedAmountExclTax;
        let startDate = tunnelData.contract.quotations[0].startDate;
        let currentPrincipal = total;
        let locale = this.props.user.locale;
        let formattedDate = formatDate(startDate, locale);
        console.log("---Formatted date", startDate, locale, "=>", formattedDate);
        let formattedCurrency = formatCurrency(total, locale);
        console.log("---Formatted currency", total, locale, "=>", formattedCurrency);
        let paymentPlan = [0, 1, 2, 3].map(i => {
          // let principal = total - total / 3 * (i-1);
          // let interest = principal * 2 / 100;
          // let payment = total / 3 + interest;
          let interest = 0;
          let payment = roundCurrency(total / 3);
          if (i === 3) payment = currentPrincipal;
          let principal = formatCurrency(currentPrincipal);
          if (i > 0) currentPrincipal -= payment;
          interest = formatCurrency(interest);
          payment = formatCurrency(payment);
          let date = formatDate(
            new Date(startDate.getFullYear(), startDate.getMonth() + i, startDate.getDate()),
            locale
          );
          let wording;
          if (i === 0) {
            wording = `Today - Full benefit of my purchase`;
            payment = "";
          }
          if (i === 1) wording = `${date} - First payment of `;
          if (i === 2) wording = `${date} - Second payment of `;
          if (i === 3) wording = `${date} - Final payment of `;
          return {
            id: i,
            date,
            principal,
            interest,
            payment,
            wording
          };
        });
        tunnelData.contract.quotations[0].simulation = {
          formattedDate,
          formattedCurrency,
          paymentPlan
        };
      } catch (error) {
        console.log("!!! Invalid tunnelData !!!", this.props.tunnelData, error);
      }
    }

    this.state = tunnelData;
  }

  render() {
    let componentProps = { ...this.props, ...this.state };
    let i = 1;
    return (
      <>
        <Switch>
          <Route
            path="/split/welcome"
            render={routeProps => <Welcome {...routeProps} {...componentProps} />}
            key={i++}
          />
          <Route
            path="/split/consent"
            render={routeProps => <Consent {...routeProps} {...componentProps} />}
            key={i++}
          />
          <Route path="/split/bank" render={routeProps => <Bank {...routeProps} {...componentProps} />} key={i++} />
          <Route
            path="/split/sign-sepa-mandate"
            render={routeProps => <SignSepaMandate {...routeProps} {...componentProps} />}
            key={i++}
          />
          <Route
            path="/split/final-welcome"
            render={routeProps => <FinalWelcome {...routeProps} {...componentProps} />}
            key={i++}
          />
        </Switch>
      </>
    );
  }
}

export default PagesLayout;
