import React, { Component } from "react";

class Consent extends Component {
  state = {};

  componentDidMount() {}

  render() {
    let contract = this.props.contract;
    let quotation = contract.quotations[0];

    return (
      <div className="modal-financing">
        <div>
          <span className="payment-schedule-total">{quotation.simulation.formattedCurrency}</span>
          <h5>My purchase</h5>
          <p>Date: {quotation.simulation.formattedDate}</p>
        </div>
        <h5>My bank data</h5>
        <p>
          In order to benefit from the first free month we need to <b>check the status</b> of the account that will{" "}
          <b>pay the purchase</b>.
        </p>
        <p>
          you only need to start session with <b>the same data</b> with which you access your{" "}
          <b>online banking services</b>.
        </p>
        <br />
        <div className="financing-icon-list">
          <div>
            <i className="fa fa-shield" />
            <h6>Secure</h6>
            <p>We don't store user name or password</p>
          </div>
          <div>
            <i className="fa fa-lock" />
            <h6>Confidential</h6>
            <p>No one sees your data</p>
          </div>
          <div>
            <i className="fa fa-rocket" />
            <h6>Fast</h6>
            <p>We answer immediatly</p>
          </div>
        </div>
        <div className="financing-consent">
          <span>
            <input
              type="checkbox"
              checked={this.state.termOfServiceChecked}
              onClick={() => this.setState({ termOfServiceChecked: this.state.termOfServiceChecked ? false : true })}
            />
          </span>
          <span>
            <p>
              I agree to use the experian service and agree to the terms of services. I allow my transaction history and
              other information related to my bank account to be shared with CACF Bankia in order to verify my identity,
              perform risk analysis and solvency checks.
            </p>
          </span>
        </div>
        <div className="financing-button-div">
          <button type="button" className="btn-solid btn" onClick={() => this.props.onSetStep("Bank")}>
            Access my bank
          </button>
        </div>
        <div className="text-right">
          <button type="button" className="btn-simple" onClick={() => this.props.onSetStep(-1)}>
            back
          </button>
        </div>
      </div>
    );
  }
}

export default Consent;
