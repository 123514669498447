import React, { Component } from "react";
import ReactGA from 'react-ga';
import { Route, Switch } from "react-router-dom";
import SplitPagesLayout from "../split/SplitPagesLayout";
import UzitPagesLayout from "../uzit/UzitPagesLayout";
import { authenticationService } from "./utils";
import { convertDateStringsToDates } from "./utils.common";
import ApiService from "./service/ApiService";
import RouteWithAccessKeys from "../uzit/RouteWithAccessKeys";
import Error404Page from "../uzit/Error404Page";
import { AccessKeysContext } from "../context/AccessKeysContext";
import { userConfigurationService } from "./utils.js";
import {
  flagTunnelDataForPaylineNavigationCases,
  PAYLINE_RETURN_CODES
} from "./service/BusinessLogicService"
const axios = require("axios");
const errorCodeDetectionRegex = /[0-9]{3}/g;
const emptyString = "";
const paylineCodeUrlParamName = "?code="

class PagesLayout extends Component {
  state = {
    tunnelData: {
      data: {},
      accessKeys: {}
    }
  };

  async componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      console.log("Init GA tag for production");
      ReactGA.initialize('UA-76715818-5', {
        debug: true
      });
    } else {
      console.log("Init GA tag for QA");
      ReactGA.initialize('UA-181806712-1', {
        debug: true
      });
    }

    const { history, incrementLoading, decrementLoading } = this.props;
    ApiService.register({ history, incrementLoading, decrementLoading });

    console.log("event added to prevent back button in pagesLayout.jsx");
    window.addEventListener("popstate", () => {
      console.log("PopState event triggered in PagesLayout.jsx");
      this.props.history.go(1);
    });
    console.log("window.location.pathname in pageLayout = ", window.location.pathname)
    let tunnelData = {};
    tunnelData = this.handleNetheosRestartURLForNavigationCases(tunnelData);

    history.push("/");
    console.log("Page Layout.jsx, history is ", history);
    authenticationService.userHasLoggedIn(); // previous call came back with a valid token => we are now logged in

    const query = new URLSearchParams(this.props.location.search);
    // todo clement implémenter une API qui permet d'identifier le tunnel souhaité en fonction de l'opl
    let tunnelName = query.get("tunnel") || "tunnel-uzit";
    let tunnelDataString = query.get("tunnelData");
    let opl;

    // cas tunnel data injecté par l'url
    if (tunnelDataString) {
      try {
        tunnelData = JSON.parse(tunnelDataString);
        convertDateStringsToDates(tunnelData);
        opl = query.get("opl");
        console.log("TUNNEL DATA STRING PARSED OPL = ", opl);
        if (!tunnelData.opl && opl && opl !== "") {
          tunnelData = this.handleUrlParamFromPaylineAndFlagTunnelDataForNavigationCases(opl, tunnelData);
        }
      } catch (error) {
        this.setState({ error: true });
        console.error("!!! Invalid tunnelData in pagesLayout.jsx !!!", tunnelDataString);
      }
    }

    if (tunnelData) {
      opl = query.get("opl");
      if (!tunnelData.opl && opl && opl !== "") {
        console.log("PagesLayout-ComponentDidMount opl param detected : value is", opl);
        tunnelData = this.handleUrlParamFromPaylineAndFlagTunnelDataForNavigationCases(opl, tunnelData);
      }
      const scriptUrl = "/api/script/runs/" + tunnelName;
      console.log("PagesLayout-ComponentDidMount : BEFORE calling script server tunnelData =", tunnelData);
      const result = await ApiService.post(scriptUrl, { tunnelData });
      console.log("PagesLayout-ComponentDidMount : AFTER calling script server tunnelData =", result.tunnelData);
      if (result && result.tunnelData) {
        tunnelData = result.tunnelData;
        let user = this.setApplicationLangageFromCustomerIfNeeded(tunnelData);
        this.setState({ tunnelData, user });
        const newStep = this.findCurrentStepFromTunnelData(tunnelData);
        ReactGA.pageview(newStep.route);
        this.props.history.push(newStep.route);
      }
      else {
        this.navigateToCustomFailPage(result, tunnelData);
      }
    }
  }

  navigateToCustomFailPage(requestResult, tunnelData) {
    // TODO CLEMENT UNE FOIS L API PRETE POUR AVOIR LE NOM DU TUNNEL VIA L OPL, remplacer le /"uzit" par du dynamique, impose de garder /fail pour la fail page generéqique
    this.setApplicationLangageFromCustomerIfNeeded(tunnelData);
    let errorCode = parseInt(requestResult.toString().match(errorCodeDetectionRegex),10);
    let title = tunnelData?.failPageMessages?.[errorCode]?.title;
    let message = tunnelData?.failPageMessages?.[errorCode]?.message;
    this.props.history.push("/uzit/fail", {"title" : title, "message" : message});
    console.log("Page Layout.jsx, history is ", this.props.history);
  }

  setApplicationLangageFromCustomerIfNeeded(tunnelData){
    if (userConfigurationService.getUserConfiguration() === null) {
      // set locale for user (both shop and server can define it just before)
      let user = (tunnelData && tunnelData.user) || { locale: "fr-FR" };
      // this is neededby the "loc" API
      userConfigurationService.register({ user });
      return user;
    }
    return userConfigurationService.getUserConfiguration();
  }

  handleNetheosRestartURLForNavigationCases(tunnelData) {
    tunnelData.netheosRestart = false;
    let pathName = window.location.pathname.toString();
    if (pathName.includes("/uuid")) {
      console.log("netheos restart detected in PagesLayout");
      tunnelData.netheosRestart = true;
      tunnelData.urlUUIDToken = pathName.replace("/uuid/", "");
    }
    return tunnelData;
  }

  handleUrlParamFromPaylineAndFlagTunnelDataForNavigationCases(oplUrlParam, tunnelData) {
    tunnelData = flagTunnelDataForPaylineNavigationCases(tunnelData, oplUrlParam);
    oplUrlParam = this.correctOplUrlParam(oplUrlParam);
    tunnelData.opl = oplUrlParam;
    return tunnelData;
  }

  correctOplUrlParam(oplUrlParam) {
    for (const [key, value] of Object.entries(PAYLINE_RETURN_CODES)) {
      if (oplUrlParam.includes(value.code)) {
        oplUrlParam = oplUrlParam.replace(paylineCodeUrlParamName + value.code, emptyString);
      }
    }
    return oplUrlParam;
  }

  removeTunnelDataError = path => {
    const { tunnelData } = this.state;
    this.setState({
      tunnelData: {
        ...tunnelData,
        errors: tunnelData.errors.filter(error => error.path !== path)
      }
    });
  };

  handleSetStep = async (data = {}) => {
    const { history } = this.props;
    const { tunnelData } = this.state;
    try {
      let newTunnelData = {
        ...tunnelData,
        data: { ...tunnelData.data, ...data }
      };

      const currentStep = this.findCurrentStepFromTunnelData(tunnelData);
      console.log("PagesLayout-HandleSetStep : RUN script " + currentStep.runUrl);
      console.log("PagesLayout-HandleSetStep : BEFORE calling script server tunnelData =", tunnelData);
      const result = await ApiService.post(currentStep.runUrl, {
        tunnelData: newTunnelData
      });
      let nextTunnelData;
      if (result && result.tunnelData) {
        console.log("PagesLayout-HandleSetStep : AFTER calling script server tunnelData =", result.tunnelData);
        nextTunnelData = result.tunnelData;
        const newStep = this.findCurrentStepFromTunnelData(nextTunnelData);
        this.setState({ tunnelData: nextTunnelData });
        history.push(newStep.route);
      }
      else {
        this.navigateToCustomFailPage(result, tunnelData)
      }
    } catch (error) {
      console.error(error);
    }
  };

  findCurrentStepFromTunnelData(tunnelData) {
    const stepId = tunnelData.step;
    const stepIndex = tunnelData.breadcrumbs.findIndex(step => step.id === stepId);
    const currentStep = tunnelData.breadcrumbs[stepIndex];
    if (currentStep) {
      return currentStep;
    }
    throw "Error : no step with id " + stepId + " has been found in tunnelData";
  }

  render() {
    const { tunnelData } = this.state;

    let componentProps = {
      ...this.props,
      ...this.state,
      onSetStep: this.handleSetStep,
      tunnelData: tunnelData,
      removeTunnelDataError: this.removeTunnelDataError
    };
    let i = 1;
    return (
      <AccessKeysContext.Provider value={tunnelData ? tunnelData.accessKeys : {}}>
        <Switch>
          <Route
            path="/split"
            render={routeProps => <SplitPagesLayout {...routeProps} {...componentProps} />}
            key={i++}
          />
          <Route
            path="/uzit"
            render={routeProps => <UzitPagesLayout {...routeProps} {...componentProps} />}
            key={i++}
          />
          <RouteWithAccessKeys
            path="/404"
            render={routeProps => <Error404Page {...routeProps} {...this.props} {...componentProps} />}
          />
        </Switch>
      </AccessKeysContext.Provider>
    );
  }
}

export default PagesLayout;
