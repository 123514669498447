import React, { Component } from "react";
import { Button, Col, Container, Form, FormGroup, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import defaultLogo from "../asset/img/logo.jpg";
import * as FinalForm from "react-final-form";
import { deepSet, deepGet } from "../core/utils.common";
import SelectField from "../form/SelectField";
import TextField from "../form/TextField";
import DateField from "../form/DateField";
const emptyTunnelData = { data: {} };
const YEAR_FORMAT = "YYYY";
class Client2Page extends Component {
  onSubmit = async values => {
    const submitErrors = await this.props.onSetStep(values);
    console.log(submitErrors);
    return submitErrors;
  };

  render() {
    const { tunnelData = emptyTunnelData } = this.props;
    const hasServerErrors = !!tunnelData.errors;
    return (
      <>
        {/* ————— TOPBAR ————— */}
        <header>
          <nav className="text-center">
            <Row className="justify-content-center no-gutters">
              <Col xs={5} md={3} lg={2}>
                <a href="https://www.uzit-direct.com/" title="UZ'it">
                  <img src={defaultLogo} alt="UZ'it" />
                </a>
              </Col>
            </Row>
          </nav>
        </header>
        <Row className="justify-content-center no-gutters">
          <Col sm={12}>
            <ul className="breadcrumb">
              <li>My contract choices</li>
              <li>My addresses</li>
              <li>Upfront</li>
              <li className="active">My contract</li>
              <li>Finalization</li>
            </ul>
          </Col>
        </Row>
        {/* ————— CONTENT ————— */}
        <Container>
          <Row>
            <Col lg={12} sm={12}>
              <h1>My contract</h1>
              <div className="data">
                <h4>
                  <span className="step">Step 2</span>
                </h4>
                <p className="eligibility">
                  The information below is necessary for our renter for the constitution of your rental file.
                  <br />
                  To meet our eligibility conditions, you must have regular activity income and not have any current
                  payment incidents.
                </p>
                <FinalForm.Form
                  initialValues={tunnelData.data}
                  validate={values => {
                    // server side errors
                    const { tunnelData = {} } = this.props;
                    let errors = {};
                    if (!tunnelData.errors) {
                      return;
                    }
                    tunnelData.errors.forEach(error => {
                      const valueNotChanged = deepGet(tunnelData.data, error.path) === deepGet(values, error.path);
                      if (valueNotChanged) {
                        deepSet(errors, error.path, error.message);
                      }
                    });
                    return errors;
                  }}
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row className="justify-content-center">
                        <Col lg={11} md={9}>
                          <Row>
                            <Col lg={3} sm={12} md={6}>
                              <h3 className="text-center margin-0">
                                <i className="fas fa-briefcase"></i> Occupation
                              </h3>
                              <div className="data-inner-block">
                                <SelectField
                                  disabled
                                  name="customer.occupationCode"
                                  label="Occupation"
                                  options={[]}
                                  hasServerErrors={hasServerErrors}
                                />
                                <DateField
                                  name="customer.employmentDate"
                                  label="Since..."
                                  dateFormat={YEAR_FORMAT}
                                  dateFormatServer={"YYYY-MM-DD"}
                                  hasServerErrors={hasServerErrors}
                                />
                              </div>
                            </Col>
                            <Col lg={3} sm={12} md={6}>
                              <h3 className="text-center margin-0">
                                <i className="fas fa-piggy-bank"></i> Finances
                              </h3>
                              <div className="data-inner-block">
                                <FormGroup>
                                  <Form.Label>Your net monthly income</Form.Label>
                                  <Row noGutters>
                                    <Col xs={7} sm={7}>
                                      <TextField
                                        noFormGroup
                                        name="customer.netMonthlyIncome"
                                        placeholder="€"
                                        hasServerErrors={hasServerErrors}
                                      />
                                    </Col>
                                    <Col xs={5} sm={5}>
                                      <SelectField
                                        noFormGroup
                                        disabled
                                        name="customer.numberMonthsIncome"
                                        options={[]}
                                        hasServerErrors={hasServerErrors}
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Form.Label>Your other monthly income</Form.Label>
                                  <Row noGutters>
                                    <Col lg={10} xs={10}>
                                      <TextField
                                        noFormGroup
                                        name="customer.otherIncome"
                                        placeholder="€"
                                        hasServerErrors={hasServerErrors}
                                      />
                                    </Col>
                                    <Col lg={2} xs={2}>
                                      <OverlayTrigger
                                        key="left"
                                        placement="left"
                                        overlay={<Tooltip>Property income or alimony paid</Tooltip>}
                                      >
                                        <Button variant="dark">
                                          <i className="fas fa-question"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Form.Label>Your share of monthly charges</Form.Label>
                                  <Row noGutters>
                                    <Col lg={10} xs={10}>
                                      <TextField
                                        noFormGroup
                                        name="customer.rent"
                                        placeholder="€"
                                        hasServerErrors={hasServerErrors}
                                      />
                                    </Col>
                                    <Col lg={2} xs={2}>
                                      <OverlayTrigger
                                        key="left"
                                        placement="left"
                                        overlay={<Tooltip>Rent or mortgage</Tooltip>}
                                      >
                                        <Button variant="dark">
                                          <i className="fas fa-question"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    </Col>
                                  </Row>
                                </FormGroup>
                                <FormGroup>
                                  <Form.Label>Your other monthly charges</Form.Label>
                                  <Row noGutters>
                                    <Col lg={10} xs={10}>
                                      <TextField
                                        noFormGroup
                                        name="customer.otherCharges"
                                        placeholder="€"
                                        hasServerErrors={hasServerErrors}
                                      />
                                    </Col>
                                    <Col lg={2} xs={2}>
                                      <OverlayTrigger
                                        key="left"
                                        placement="left"
                                        overlay={<Tooltip>Credit (excluding mortgage) and / or alimony paid</Tooltip>}
                                      >
                                        <Button variant="dark">
                                          <i className="fas fa-question"></i>
                                        </Button>
                                      </OverlayTrigger>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </div>
                            </Col>
                            <Col lg={3} sm={12} md={6}>
                              <h3 className="text-center margin-0">
                                <i className="fas fa-home"></i> Housing
                              </h3>
                              <div className="data-inner-block">
                                <SelectField
                                  disabled
                                  name="customer.address.housingType"
                                  label="Housing"
                                  options={[]}
                                  hasServerErrors={hasServerErrors}
                                />
                                <DateField
                                  name="customer.address.residenceDate"
                                  label="Since..."
                                  dateFormat={YEAR_FORMAT}
                                  dateFormatServer={"YYYY-MM-DD"}
                                  hasServerErrors={hasServerErrors}
                                />
                                <SelectField
                                  disabled
                                  name="customer.maritalStatus"
                                  label="Family situation"
                                  options={[]}
                                  hasServerErrors={hasServerErrors}
                                />
                                <TextField
                                  name="customer.dependentChildrenNumber"
                                  label="Number of dependent children"
                                  hasServerErrors={hasServerErrors}
                                />
                              </div>
                            </Col>
                            <Col lg={3} sm={12} md={6}>
                              <h3 className="text-center margin-0">
                                <i className="fas fa-money-check"></i> Bank account
                              </h3>
                              <div className="data-inner-block">
                                <TextField
                                  disabled
                                  name="customer.bankAccountDTO.iban"
                                  label="IBAN"
                                  hasServerErrors={hasServerErrors}
                                />
                                <TextField
                                  disabled
                                  name="customer.bankAccountDTO.bic"
                                  label="BIC"
                                  hasServerErrors={hasServerErrors}
                                />
                                <TextField
                                  name="customer.yearBankSeniority"
                                  label="Bank seniority"
                                  hasServerErrors={hasServerErrors}
                                />
                              </div>
                            </Col>
                          </Row>

                          <hr className="invisible"></hr>

                          <Row className="justify-content-lg-center">
                            <Col sm={12} lg={6}>
                              <FormGroup className="optin">
                                <Form.Check
                                  label="I agree to receive electronically commercial offers from companies in the BNP Paribas Finance group."
                                  type="checkbox"
                                />
                                <Form.Check
                                  label="I oppose the receipt of commercial offers by post or telephone from BNP Personal Finance group companies."
                                  type="checkbox"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row className="justify-content-center">
                        <Col lg={3} md={6} sm={12}>
                          <Button
                            variant="primary"
                            type="submit"
                            className="btn-solid btn"
                            onClick={() => {
                              console.log(tunnelData);
                            }}
                          >
                            Validate and sign my contract
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                />
              </div>
              <footer>
                <Row>
                  <Col>
                    <p>
                      Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance
                      Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU
                      with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °
                      433 911 799.
                    </p>
                    <p>
                      The information requested is for the management of your leasing request. Unless otherwise stated,
                      they are mandatory and in the absence of a complete response, your request may be refused. If you
                      choose not to let us know, we will not be able to process your request. They are intended for the
                      Lessor, Personal Finance Location, data controller. Through it, you will be able to receive
                      proposals from partners. For more information on the processing of your personal data by Personal
                      Finance Location and on your rights (right of access, rectification, erasure, limitation of
                      processing, data portability, right of opposition and right to organize the management of your
                      data after your death) you can refer to{" "}
                      <a href="informationnotice.html">the information notice on the use of personal data</a> or contact
                      us at the following address at Personal Finance Location - Customer Service - 62061 Cedex 9 -
                      <a href="mailto:consommateurs.donneespersonnelles@cetelem.fr">
                        consommateurs.donneespersonnelles@cetelem.fr
                      </a>
                    </p>
                    <p>
                      The information requested is for the management and evaluation of your Service Contract. Unless
                      otherwise stated, they are mandatory and in the absence of a complete response, your request may
                      be refused. If you choose not to let us know, we will not be able to process your request. They
                      are intended for EVOLLIS, responsible for the processing. Through it, you will be able to receive
                      proposals from partners. For more information on the processing of your personal data by EVOLLIS
                      and on your rights (right of access, rectification, erasure, limitation of processing, data
                      portability, right of opposition and right to organize the management of your data after your
                      death) you can refer <a href="datausage.html">here to the use of your data</a> or contact us at
                      the following address EVOLLIS - Customer Service - 22-26 quai de Bacalan - 33300 Bordeaux or at
                      <a href="mailto:protectiondonnees@evollis.com">protectiondonnees@evollis.com</a>
                    </p>
                    <p>
                      Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de
                      Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125
                      71 - ORIAS number: 13003909.
                    </p>
                  </Col>
                </Row>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Client2Page;
