import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import defaultLogo from "../asset/img/logo.jpg";
import { loc } from "../core/utils";

class PaymentKOPage extends Component {
  state: {};

  componentDidMount() {}

  render() {
    return (
      <>
        {/* ————— TOPBAR ————— */}
        <header>
          <nav className="text-center">
            <Row className="justify-content-center no-gutters">
              <Col xs={5} md={3} lg={2}>
                <a href="https://www.uzit-direct.com/" title="UZ'it">
                  <img src={defaultLogo} alt="UZ'it" />
                </a>
              </Col>
            </Row>
          </nav>
        </header>
        {/* ————— CONTENT ————— */}
        <Container>
          <div className="ok-ko">
            <Row className="justify-content-center">
              <Col sm={8}>
                <span className="ico-ko">
                  <i class="fal fa-times-circle"></i>
                </span>
                <h1>{loc`Your card has been refused`}</h1>
                <p>{loc`Your order is canceled. The amount of the upfront will not be deducted from you.`}</p>
                <p>{loc`The UZ'it team thanks you for your request.`}</p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={3} sm={12}>
                <Button href="http://www.uzit-direct.com/" className="btn-solid btn">
                {loc`Back to shop`}
                </Button>
              </Col>
            </Row>
          </div>
          <footer>
            <Row>
              <Col>
                <p>{loc`Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °433 911 799.`}</p>
                <p>{loc`Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125 71 - ORIAS number: 13003909.`}</p>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default PaymentKOPage;
