import React, { Component } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import * as FinalForm from "react-final-form";
import defaultLogo from "../asset/img/logo.jpg";
import { loc } from "../core/utils";
import { deepGet } from "../core/utils.common";
import _ from "lodash";
import CheckboxField from "../form/CheckboxField";
import {
  REPOSITORY_SEPARATOR,
  computeServicePackPriceFromRent,
  FRANCE_COUNTRY_CODE, getCivilStatuses, convertTitleEnumNameToCode
} from "../core/service/BusinessLogicService";
import {retrieveRepositoryLabelFromCode} from "../core/service/BusinessLogicService";
import ApiService from "../core/service/ApiService";
import ReactGA from 'react-ga';

class SummaryPage extends Component {
  state = {
    submitButtonLocked : false
  };

  async componentDidMount() {
    let {tunnelData} = this.props;
    if (tunnelData.data.person.birthCountryCode === FRANCE_COUNTRY_CODE) {
      const getCitiesByIdUrl = this.props.tunnelData.apiUrl
          + "middle-business-service/api/v2/repository/cities/by-id/"
          + tunnelData.data.person.birthCity.substring(0, tunnelData.data.person.birthCity.indexOf(REPOSITORY_SEPARATOR))
          + "/old";
      const token = this.props.tunnelData.access_token;
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      };
      let result = await ApiService.getForEvollisApi(getCitiesByIdUrl, headers);
      this.setState({cityRetrievedById : result.zipCode + REPOSITORY_SEPARATOR + result.name});
    }
    let civilStatusesAsMap = await getCivilStatuses(this.props?.tunnelData, false);
    let personTitleCode = this.props?.tunnelData?.data?.person.title;
    let retrievedPersonTitleName = await retrieveRepositoryLabelFromCode(civilStatusesAsMap, personTitleCode);
    this.setState({titleEnumName : retrievedPersonTitleName || ""});
    if (process.env.NODE_ENV === "production") {
      console.log("Init GA tag for production");
      ReactGA.initialize('UA-76715818-5', {
        debug: true
      });
    } else {
      console.log("Init GA tag for QA");
      ReactGA.initialize('UA-181806712-1', {
        debug: true
      });
    }
    const newStep = this.findCurrentStepFromTunnelData(tunnelData);
    ReactGA.pageview(newStep.route);
  }

    render() {
      let {tunnelData} = this.props;
      console.log("SummaryPage render's this.state = ", this.state);
      const product = deepGet(tunnelData, ["data", "order", "products", 0])
          || {};
      if (!tunnelData.data.order) {
        tunnelData.data.order = {selectedServicePack: {}, rent: {}}
      }
      let person = this.props.tunnelData.data.person || {};
      let customer = this.props.tunnelData.data.customer || {
        employmentDate: "2020",
        address: {residenceDate: "2020"},
        bankAccountDTO: {}
      };

      return (
          <>
            {/* ————— TOPBAR ————— */}
            <header>
              <nav className="text-center">
                <Row className="justify-content-center no-gutters">
                  <Col xs={5} md={3} lg={2}>
                    <a href="https://www.uzit-direct.com/" title="UZ'it">
                      <img src={defaultLogo} alt="UZ'it"/>
                    </a>
                  </Col>
                </Row>
              </nav>
            </header>
            <Row className="justify-content-center no-gutters">
              <Col sm={12}>
                <ul className="breadcrumb">
                  <li>{loc`My addresses`}</li>
                  <li>{loc`My credit card data`}</li>
                  <li className="active">{loc`My contract`}</li>
                  <li>{loc`Finalization`}</li>
                </ul>
              </Col>
            </Row>
            {/* ————— CONTENT ————— */}
            <Container>
              <Row>
                <Col lg={9} sm={12}>
                  <h1>{loc`Summary of your data and your order`}</h1>
                  <Row>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-id-card"></i>{" "}
                        {loc`Identity`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                          <li><label>{loc`Title`}</label><span
                              className="summary-data">{this.state.titleEnumName || ""}</span>
                          </li>
                          <li><label>{loc`Last name`}</label> <span
                              className="summary-data">{person.name}</span></li>
                          {person.maidenName && <li>
                            <label>{loc`Maiden name`}</label> <span
                              className="summary-data">{person.maidenName}</span>
                          </li>}
                          <li><label>{loc`First name`}</label> <span
                              className="summary-data">{person.firstName}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-map-marker-alt"></i>{" "}
                        {loc`Address`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                          <li><label>{loc`Address`}</label> <span
                              className="summary-data">{customer.address.address}</span>
                          </li>
                          {customer.address.additionalAddress && <li>
                            <label>{loc`Address 2`}</label> <span
                              className="summary-data">{customer.address.additionalAddress}</span>
                          </li>}
                          {customer.address.locality && <li>
                            <label>{loc`Locality`}</label> <span
                              className="summary-data">{customer.address.locality}</span>
                          </li>}
                          <li><label>{loc`Zip`}</label> <span
                              className="summary-data">{customer.address.postalCode}</span>
                          </li>
                          <li><label>{loc`City`}</label> <span
                              className="summary-data">{customer.address.city}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-birthday-cake"></i>{" "}
                        {loc`Birth`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                          <li><label>{loc`Birthdate`}</label> <span
                              className="summary-data">{person.birthDate}</span>
                          </li>
                          <li><label>{loc`Department of birth`}</label> <span
                              className="summary-data">{retrieveRepositoryLabelFromCode(
                              tunnelData.birhtDepartments,
                              person.birthDepartmentCode)}</span></li>
                          <li><label>{loc`City of birth`}</label> <span
                              className="summary-data">{person.birthCountryCode !== FRANCE_COUNTRY_CODE ? person.birthCity : this.state.cityRetrievedById}</span>
                          </li>
                          <li><label>{loc`Country of birth`}</label> <span
                              className="summary-data">{retrieveRepositoryLabelFromCode(
                              tunnelData.countries,
                              person.birthCountryCode)}</span></li>
                          <li><label>{loc`Citizenship`}</label> <span
                              className="summary-data">{retrieveRepositoryLabelFromCode(
                              tunnelData.countries,
                              person.citizenshipCountryCode)}</span></li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-phone"></i> {loc`Contact`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                          <li><label>{loc`Mobile`}</label> <span
                              className="summary-data">{customer.address.mobilePhone}</span>
                          </li>
                          <li><label>{loc`Email`}</label> <span
                              className="summary-data">{customer.address.email}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-briefcase"></i>
                        {loc`Occupation`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                          <li><label>{loc`Occupation`}</label> <span
                              className="summary-data">{retrieveRepositoryLabelFromCode(
                              tunnelData.socioProfessionalcategories,
                              customer.occupationCode)}</span></li>
                          {customer.employmentDate && <li>
                            <label>{loc`Since...`}</label> <span
                              className="summary-data">{customer.employmentDate.substr(
                              0, 4)}</span></li>}
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-piggy-bank"></i>
                        {loc`Finances`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                          <li><label>{loc`Your net monthly income`}</label>
                            <span
                                className="summary-data">{customer.netMonthlyIncome} {loc`within`} {customer.numberMonthsIncome} {loc`months`}</span>
                          </li>
                          <li><label>{loc`Your other monthly income`}</label>
                            <span
                                className="summary-data">{customer.otherIncome}</span>
                          </li>
                          <li>
                            <label>{loc`Your share of monthly charges`}</label>
                            <span
                                className="summary-data">{customer.rent}</span>
                          </li>
                          <li><label>{loc`Your other monthly charges`}</label>
                            <span
                                className="summary-data">{customer.otherCharges}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-home"></i> {loc`Housing`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                          <li><label>{loc`Housing`}</label> <span
                              className="summary-data">{retrieveRepositoryLabelFromCode(
                              tunnelData.housingTypes,
                              customer.address.housingType)}</span></li>
                          <li><label>{loc`Since...`}</label> <span
                              className="summary-data">{customer.address.residenceDate.substr(
                              0, 4)}</span></li>
                          <li><label>{loc`Family situation`}</label> <span
                              className="summary-data">{retrieveRepositoryLabelFromCode(
                              tunnelData.familySituations,
                              customer.maritalStatus)}</span></li>
                          <li><label>{loc`Number of dependent children`}</label>
                            <span
                                className="summary-data">{customer.dependentChildrenNumber}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} sm={12} md={6}>
                      <h3 className="text-center margin-0">
                        <i className="fas fa-money-check"></i>{" "}
                        {loc`Bank account`}
                      </h3>
                      <div className="data-inner-block">
                        <ul>
                        <li><label>{loc`IBAN`}</label> <span
                              className="summary-data">{customer.bankAccountDTO.iban}</span>
                          </li>
                        <li><label>{loc`BIC or SWIFT`}</label> <span
                              className="summary-data">{customer.bankAccountDTO.bic}</span>
                          </li>
                          <li><label>{loc`Bank Seniority`}</label> <span
                              className="summary-data">{customer.yearBankSeniority.substr(
                                0, 4)}</span>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>

                  <hr className="invisible"></hr>


                  <FinalForm.Form
                      initialValues={tunnelData.data.optinAnswers}
                      onSubmit={this.onSubmit}
                      render={(
                          {
                            handleSubmit,
                            values,
                            submitting,
                            validating,
                            valid,
                          }
                      ) => (
                          <Form onSubmit={handleSubmit}>
                            <Row className="justify-content-center">
                              <Col sm={12}>
                                <Row className="justify-content-lg-center">
                                  <Col lg={6} sm={12}>
                                    <CheckboxField
                                        label={loc`I agree to receive electronically commercial offers from companies in the BNP Paribas Personal Finance group.`}
                                        name="optinEmailPF"
                                    />
                                    <CheckboxField
                                        label={loc`I oppose the receipt of commercial offers by post or telephone from BNP Paribas Personal Finance group companies.`}
                                        name="optoutPostTelephonePF"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="justify-content-between">
                              <Col lg={3} md={4} sm={12}>
                                <Button
                                    variant="outline-primary"
                                    onClick={this.goBackToClientPage}
                                    className="btn-outline-primary btn"
                                >
                                  {loc`Edit my data`}
                                </Button>
                              </Col>
                              <Col lg={2} md={3} sm={12}>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="btn-solid btn"
                                    disabled={this.state?.submitButtonLocked === true}
                                >
                                  {loc`Validate`}
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                      )}/>

                  <footer>
                    <Row>
                      <Col>
                        <p>{loc`Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Long-term rental contract, subject to acceptance of the file by the Lessor Personal Finance Location and after expiration of the legal withdrawal period. Personal Finance Location - SASU with a capital of € 1,500,000 - Headquarters: 1 boulevard Haussmann - 75009 Paris - RCS Paris N °433 911 799.`}</p>
                        <p>
                          {loc`The information requested is for the management of your leasing request. Unless otherwise stated, they are mandatory and in the absence of a complete response, your request may be refused. If you choose not to let us know, we will not be able to process your request. They are intended for the Lessor, Personal Finance Location, data controller. Through it, you will be able to receive proposals from partners. For more information on the processing of your personal data by Personal Finance Location and on your rights (right of access, rectification, erasure, limitation of processing, data portability, right of opposition and right to organize the management of your data after your death) you can refer to`}
                          <a href="https://static-evollis.evollis.com/pdf/clause_donnees_personnelles.pdf" target="_blank">{loc`the information notice on the use of personal data`}</a>
                          {loc`or contact us at the following address at Personal Finance Location - Customer Service - 62061 Cedex 9 - `}
                          <a href="mailto:consommateurs.donneespersonnelles@cetelem.fr">{loc`consommateurs.donneespersonnelles@cetelem.fr`}</a>
                        </p>
                        <p>{loc`You can object to receiving commercial prospecting by telephone by registering for free on the list of opposition to telephone canvassing on the website www.bloctel.gouv.fr or by post to - Société Opposetel, Service Bloctel, 6 rue Nicolas Siret 10000 Troyes. This registration prohibits a professional from canvassing you by telephone, unless this solicitation occurs in the context of the execution of a current contract and it has a connection with the subject of this contract.`}</p>
                        <p>{loc`The evaluation of your file is subject to automated processing, which may result in the refusal of your subscription request.You have the right to obtain human intervention, to express your point of view and to contest the decision by contacting us at the following address: Personal Finance Location - 62061 Arras - Cedex 9 or by email: serviceclientspflocation @bnpparibas -pf.com`}</p>
                        <p>{loc`Service contract managed by EVOLLIS - SAS with capital of € 1,277,010 - Headquarters: 22-26 Quai de Bacalan 33300 Bordeaux - RCS Bordeaux N ° 530 612 571 - Intra-community VAT number: FR 195 306 125 71 - ORIAS number: 13003909.`}</p>
                      </Col>
                    </Row>
                  </footer>

                </Col>
                {/* ————— BASKET ————— */}
                {/* ————— BASKET ————— */}
                {product && (
                    <Col lg={3} sm={12} className="d-lg-block">
                      <aside className="basket">
                        <h3>{loc`My basket`}</h3>

                        <Row>
                          <Col xs={4}><img src={product.picture} alt={product.designation} /></Col>
                          <Col className="align-self-center">
                            <label>{product.designation}</label>
                            
                          </Col>
                        </Row>

                        {/* <p className="upgrade-tag">Upgrade activated</p>
                      <hr />
                      <p className="promo-tag">Promotion label</p> */}
                        <hr />

                        <Row className="order-details">
                          <Col xs={8}>
                            <label>
                              {loc`Monthly payment`}<br/>
                              <span className="small-text">{loc`including services pack at`}</span>
                            </label>
                          </Col>
                          <Col xs={4}>
                            <p className="product-price">{tunnelData.data.order.rent.monthlyTotal}€ <span className="small-text">{loc`/month`}</span></p>
                            <p className="small-text text-right">{computeServicePackPriceFromRent(tunnelData.data.order.rent)}€ {loc`/month`}</p>
                          </Col>
                          <Col xs={12}>
                            <div className="included-services">
                              <strong>{tunnelData.data.order.selectedServicePack.name}</strong>
                              <span dangerouslySetInnerHTML={{ __html: tunnelData.data.order.selectedServicePack.description }} />
                            </div>
                          </Col>
                          <Col xs={8}><label>{loc`Upfront`}</label></Col>
                          <Col xs={4}><p className="product-price">{tunnelData.data.order.rent.firstCustomerPayment}€</p></Col>
                          <Col xs={8}><label>{loc`Contract duration`}</label></Col>
                          <Col xs={4}><p className="product-price">{tunnelData.data.order.rent.duration} {loc`months`}</p></Col>
                          <Col xs={8}><label>{loc`Shipping fees`}</label></Col>
                          <Col xs={4}><p className="product-price">{loc`Offered`}</p></Col>
                        </Row>

                      </aside>
                    </Col>
                )}
              </Row>
            </Container>
          </>
      );
    }

    findCurrentStepFromTunnelData(tunnelData) {
      const stepId = tunnelData.step;
      const stepIndex = tunnelData.breadcrumbs.findIndex(step => step.id === stepId);
      const currentStep = tunnelData.breadcrumbs[stepIndex];
      if (currentStep) {
        return currentStep;
      }
      throw "Error : no step with id " + stepId + " has been found in tunnelData";
    }

    onSubmit = async (values) => {
      await this.lockSubmitButton();
      this.props.tunnelData.comeBackToClientStep = false;
      this.props.onSetStep(values);
    };

    lockSubmitButton = async () => {
      await this.setState({submitButtonLocked : this.state.submitButtonLocked !== true});
    };

    goBackToClientPage = () => {
      this.props.tunnelData.comeBackToClientStep = true;
      this.props.onSetStep(this.props.tunnelData.data);
    }
  }

export default SummaryPage;
