import React, { Component } from "react";
import { Field as FinalFormField } from "react-final-form";
import { Alert, Form, FormGroup, Col } from "react-bootstrap";
import { AccessKeysContext } from "../context/AccessKeysContext";
import { REQUIRED_MESSAGE } from "./defaultMessages";
import Select from "react-select";
import ApiService from "../core/service/ApiService";
import {createRepositoryListForSelectFromEvollisApi} from "../core/service/BusinessLogicService";
import {loc} from "../core/utils";

export default class SelectField extends Component {
  state = {};

  validate = (value, accessKeys) => {
    const { name } = this.props;
    const ak = accessKeys[name] || {};
    const { required, requiredMessage = REQUIRED_MESSAGE } = { ...this.props, ...ak };
    if (required && (value === undefined || value === "")) {
      return requiredMessage;
    }
  };

  async componentDidMount() {
    await this.updateStateOptionsFromApiIfNeeded(this.props);
  }

  async componentDidUpdate(prevProps){
    if (prevProps?.otherComponentValueToBind !== this.props.otherComponentValueToBind) {
      await this.updateStateOptionsFromApiIfNeeded(this.props);
    }
  }

  async updateStateOptionsFromApiIfNeeded(props) {
    let restApiConfig = props?.restApiConfig;
    if (restApiConfig) {
      let result = await ApiService.getForEvollisApi(restApiConfig.url, restApiConfig.headers);
      result = await createRepositoryListForSelectFromEvollisApi(result, false);
      this.setState({optionsFromExternalApi : result});
    }
  }

  render() {
    return (
      <AccessKeysContext.Consumer>
        {(accessKeys = {}) => {
          const { name } = this.props;
          const ak = accessKeys[name] || {};
          let options = this.props.options;
          const {
            hidden,
            placeholder,
            label,
            className,
            xs,
            lg,
            xl,
            md,
            sm,
            xsHidden,
            smHidden,
            mdHidden,
            lgHidden,
            xlHidden,
            disabled,
            hasServerErrors,
            noFormGroup,
            initialValue,
            restApiConfig,
          } = { ...this.props, ...ak };

          if (hidden) {
            return false;
          }

          return (
            <FinalFormField
              name={name}
              type="text"
              placeholder={placeholder}
              className="form-control"
              validate={value => {
                return this.validate(value, accessKeys);
              }}
              initialValue={initialValue}
            >
              {({ input, meta }) => {
                const metaError = meta.error && (meta.touched || hasServerErrors);
                const hasError = metaError || meta.submitError;
                const cl = {
                  "d-none": xsHidden || smHidden || mdHidden || lgHidden || xlHidden,
                  "d-sm-block": !smHidden,
                  "d-sm-none": smHidden,
                  "d-md-block": !mdHidden,
                  "d-md-none": mdHidden,
                  "d-lg-block": !lgHidden,
                  "d-lg-none": lgHidden,
                  "d-xl-block": !xlHidden,
                  "d-xl-none": xlHidden,
                  "col-no-padding": true
                };
                if (className) {
                  cl[className] = true;
                }
                let selectedOptions =  null;
                let selectedOption;
                if (this.props.restApiConfig && this.state.optionsFromExternalApi) {
                  options = this.state.optionsFromExternalApi;
                }
                if (options instanceof  Array) {
                  selectedOptions = options.filter(key => key.value === input.value);
                }
                if (input.value) {
                  if (selectedOptions?.length === 0) {
                    selectedOption = {value: "technicalError", label: loc("Please choose a value again")};
                  }
                  else {
                    selectedOption = selectedOptions[0];
                  }
                }
                const hasColParam =
                  xs || lg || xl || md || sm || xsHidden || smHidden || mdHidden || lgHidden || xlHidden;
                const colParams = { className: cl, xs, lg, xl, md, sm };

                let body = [
                  <React.Fragment key="label">{label && <Form.Label>{label}</Form.Label>}</React.Fragment>,
                  <Select
                    {...input}
                    key="input"
                    className={hasError && "selectfield-error"}
                    value={selectedOption}
                    isDisabled={disabled}
                    onChange={option => {
                      option ? input.onChange(option.value) : input.onChange(undefined);
                    }}
                    options={options}
                    isClearable
                  />,
                  <React.Fragment key="error">
                    {hasError && <p className="required-message">{meta.error || meta.submitError}</p>}
                  </React.Fragment>
                ];

                if (!noFormGroup) {
                  body = <FormGroup>{body}</FormGroup>;
                }

                if (hasColParam) {
                  body = <Col {...colParams}>{body}</Col>;
                }

                return body;
              }}
            </FinalFormField>
          );
        }}
      </AccessKeysContext.Consumer>
    );
  }
}
