import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";

import PagesLayout from "./core/PagesLayout";
import { setupAxiosClient, authenticationService } from "./core/utils";
import { LoadingContext } from "./context/LoadingContext";

setupAxiosClient(window.location.origin);

class App extends Component {
  state = {
    loading: 0
  };

  incrementLoading = () => {
    const { loading } = this.state;
    this.setState({ loading: loading + 1 });
  };

  decrementLoading = () => {
    const { loading } = this.state;
    this.setState({ loading: loading - 1 });
  };

  componentDidMount() {
    authenticationService.addListener("logged", this.handleLoggedEvent);
  }

  componentWillUnmount() {
    authenticationService.removeListener("logged", this.handleLoggedEvent);
  }

  handleLoggedEvent = logged => {
    this.setState({ logged });
  };

  render() {
    let i = 1;
    const { loading } = this.state;
    const loadingProps = {
      loading: loading,
      incrementLoading: this.incrementLoading,
      decrementLoading: this.decrementLoading
    };
    return (
      <BrowserRouter>
        <LoadingContext.Provider value={{ loading: loading }}>
          <Switch>
            <Route path="/" render={routeProps => <PagesLayout {...routeProps} {...loadingProps} />} key={i++} />
          </Switch>
        </LoadingContext.Provider>
      </BrowserRouter>
    );
  }
}

export default App;
