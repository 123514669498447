import ApiService from "./ApiService";

export const PAYLINE_RETURN_CODES = {
      PAYLINE_CODE_APPROUVED : {
        code : "CODE_APPROVED",
        paylineError  : false,
        eliminatoryPaylineError : false,
      },

      PAYLINE_CODE_UNKNOWN : {
        code : "CODE_UNKNOWN",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_AUTHENTIFICATION_FAIL : {
        code : "REFUSED_CODE_AUTHENTIFICATION_FAIL",
        paylineError  : true,
        eliminatoryPaylineError : true,
      },

      PAYLINE_REFUSED_CODE_EXPIRED_CREDIT_CARD : {
        code : "REFUSED_CODE_EXPIRED_CREDIT_CARD",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_INVALID_CREDIT_CARD_NUMBER : {
        code : "REFUSED_CODE_INVALID_CREDIT_CARD_NUMBER",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_CONNECTION_TIME_OUT : {
        code : "REFUSED_CODE_CONNECTION_TIME_OUT",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_CARD_LOST : {
        code : "REFUSED_CODE_CARD_LOST",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_EXPIRED_CREDIT_CARD_BIS : {
        code : "REFUSED_CODE_EXPIRED_CREDIT_CARD_BIS",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_INSUFFICIENT_CASH : {
        code : "REFUSED_CODE_INSUFFICIENT_CASH",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED : {
        code : "REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_REFUSED_CODE_INVALID_TRANSACTION_ID : {
        code : "REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },

      PAYLINE_REFUSED_CODE_CONNECTION_PAYLINE_BANK_FAIL : {
        code : "REFUSED_CODE_MAXIMUM_DEBIT_ACHIEVED",
        paylineError  : true,
        eliminatoryPaylineError : false,
      },
    };

export function flagTunnelDataForPaylineNavigationCases(tunnelData, oplUrlParam) {
  let matchCount = 0;
  for (const [key, value] of Object.entries(PAYLINE_RETURN_CODES)) {
    if (oplUrlParam.includes(value.code)) {
      tunnelData.paylineError = value.paylineError;
      tunnelData.eliminatoryPaylineError = value.eliminatoryPaylineError;
      matchCount++;
    }
  }
  if (matchCount > 1) {
    throw "There is a problem with Payline return code : a match with two differents Payline return codes has been found.";
  }
  return tunnelData;
}


export function computeServicePackPriceFromRent(rent) {
    let computedMonthlyLeaseAmount = 0;
    if (rent.monthlyFinancedServicesAmount) {
      computedMonthlyLeaseAmount += rent.monthlyFinancedServicesAmount;
    }
    return computedMonthlyLeaseAmount.toFixed(2);
}

export function retrieveRepositoryLabelFromCode(repositoryList, code){
  console.log("retrieveRepositoryLabelFromCode with params", code, repositoryList);
  if (code && repositoryList) {
    for (const [key, value] of Object.entries(repositoryList)) {
      if(key === code){
        return value;
      }
    }
  }
}

export function createRepositoryListForRadiosFromEvollisApi(apiRequestResult) {
  let result = [];
  if (apiRequestResult) {
    for (const [key, value] of Object.entries(apiRequestResult)) {
      result.push({"name" : key, "label" : value});
    }
  }
  return result;
}

export function createRepositoryListForSelectFromEvollisApi(apiRequestResult, concatKeyAndValueInLabel){
  let result = [];
  let labelAttributValue;
  if (apiRequestResult) {
    for (const [key, value] of Object.entries(apiRequestResult)) {
      if (concatKeyAndValueInLabel === true) {
        labelAttributValue = key + REPOSITORY_SEPARATOR + value;
      }
      else {
        labelAttributValue = value;
      }
      result.push({"value" : key, "label" : labelAttributValue});
    }
  }
  return result;
}

export function retrieveRepositoryObjectFromCode(repositoryList, code){
  if (code && repositoryList) {
    for (const [key, value] of Object.entries(repositoryList)) {
      if(key === code){
        return {"value" : key, "label" : value};
      }
    }
  }
}

/** Socioprofessional category handling */
export function isASocioProfessionalCategoryWithoutContract(socioProfessionalRepositoryList, socioProfessionalCategoryCode){
  if (socioProfessionalCategoryCode) {
    if (socioProfessionalCategoryCode && socioProfessionalRepositoryList) {
      for (const [key, value] of Object.entries(socioProfessionalRepositoryList)) {
        if (socioProfessionalCategoryCode === key) {
          return true;
        }
      }
    }
  }
  return false;
}

/** Country repository handling */
export const FRANCE_COUNTRY_CODE = "F";
// used to concat repositories values for client side displaying or for ids and zipcodes concat in cities lists keys
export const REPOSITORY_SEPARATOR = " - ";

/** Person title handling */

export const misterEnumName = "MR";
export const misterEnumCode = "1";

export const mademoiselleEnumName = "MS";
export const mademoiselleEnumCode = "3";

export const missEnumName = "MRS";
export const missEnumCode = "2";

export async function getCivilStatuses(tunnelData, returnAsArray) {
  const getCivilStatusesURL = tunnelData?.apiUrl + "middle-business-service/api/v2/repository/civil-statuses";
  const token = tunnelData?.access_token;
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "*/*",
  };
  let civilStatusesFromEvollisApi = await ApiService.getForEvollisApi(getCivilStatusesURL, headers);
  // Remove Miss from civility enum cuz we can't delete it from DB (other parcours use it at the moment);
  delete civilStatusesFromEvollisApi[mademoiselleEnumCode];
  if (returnAsArray === true) {
    return createRepositoryListForRadiosFromEvollisApi(civilStatusesFromEvollisApi);
  }
  else {
    return civilStatusesFromEvollisApi;
  }
}

export function convertPersonTitleCodeToEnumName(valueToConvert) {
  if (valueToConvert === misterEnumCode) {
    return misterEnumName;
  }
  if (valueToConvert === mademoiselleEnumCode) {
    return mademoiselleEnumName;
  }
  if (valueToConvert === missEnumCode) {
    return missEnumName;
  }
  return null;
}

export function convertTitleEnumNameToCode(valueToConvert) {
  if (valueToConvert === misterEnumName) {
    return misterEnumCode;
  }
  if (valueToConvert === mademoiselleEnumName) {
    return mademoiselleEnumCode;
  }
  if (valueToConvert === missEnumName) {
    return missEnumCode;
  }
  return null;
}