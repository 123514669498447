/*
 * Copyright Evollis 2019
 * All Rights Reserved.
 *
 * All information contained herein is, and remains the property of Evollis.
 * The intellectual and technical concepts contained herein are
 * proprietary to Evollis and are protected by trade secret and copyright law.
 * Dissemination of this information or reproduction of this material
 * is STRICTLY FORBIDDEN unless prior written permission is obtained
 * from Evollis.
 */
const serverUrl = "/api";
let opl = undefined;
const evollisServer = serverUrl + "/middle-bank-service/api/v2/payment/";
//evollisCssServer : serverUrl + "/css/evollis.css",
const returnUrl = undefined;

export const init = returnUrl => {
  if (!returnUrl) {
    console.log("Error : returnUrl is not defined");
    return;
  }

  /* Include CSS
    const evollisCss = "evollisCss";
    if(!document.getElementById(evollisCss)) {
        var head  = document.getElementsByTagName('head')[0];
        let link  = document.createElement('link');
        link.id   = evollisCss;
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = evollisCssServer;
        link.media = 'all';
        head.appendChild(link);
    }*/

  let divToInject = document.getElementById("evollis-widget");
  const evollisForm = [
    "<div id='loader--evollis' class='loader--evollis'>",
    "<div class='loader-message--evollis'>",
    "<h1>Loading</h1>",
    "<div class='la-line-scale--evollis la-2x--evollis'>",
    "<div></div><div></div><div></div><div></div><div></div>",
    "</div>",
    "</div>",
    "</div>",
    "<form id='form--evollis' class='form form--evollis'>" +
      "<label name='label--evollis-card-number' class='label label--evollis'>Card Number : </label><input type='text' id='input--evollis-card-number' class='input input--evollis' required pattern='^[0-9]{16}$'/>",
    "<label name='label--evollis-expiration-date' class='label label--evollis'>Date Expiration : </label><input type='text' id='input--evollis-expiration-month' class='input input--evollis' required pattern='^[0-9]{2}$'/>" +
      " / <input type='text' id='input--evollis-expiration-year' class='input input--evollis' required pattern='^[0-9]{4}$'/>",
    "<label name='label--evollis-cvx' class='label label--evollis-cvx'>Cvx : </label><input type='text' id='input--evollis-cvx' class='input input--evollis' required pattern='^[0-9]{3}$'/>",
    "<button form='form--evollis' type='button' id='button--evollis' onclick='Evollis.getPaymentInformation()'>Submit</button>" +
      "</form><div id='acs-evollis-widget'></div>"
  ].join("<br/>");
  divToInject.innerHTML = evollisForm;
};

export const getPaymentInformation = authenticationToken => {
  let locationHref = new URL(window.location.href);
  opl = locationHref.searchParams.get("opl");
  window.sessionStorage.setItem("opl", opl);
  let request = new XMLHttpRequest();
  request.open("GET", evollisServer + "information/" + opl, true);
  if (authenticationToken) {
    request.setRequestHeader("Authorization", authenticationToken);
  } else {
    document.getElementById("loader--evollis").style.display = "block";
    request.setRequestHeader("Authorization", "Bearer " + document.getElementById("evollisToken").value);
  }
  request.onreadystatechange = callbackGetEvollisToken.bind(this, request, authenticationToken);
  request.send();
};

const callbackGetEvollisToken = (request, authenticationToken) => {
  if (request.readyState === XMLHttpRequest.DONE) {
    if (request.status === 200) {
      const paymentInformation = JSON.parse(request.responseText);
      getPaylineToken(paymentInformation, authenticationToken);
    } else {
      formatAndShowError(request);
    }
  }
};
/**
const getPaylineToken = () => {
    const webServiceCreditor = document.getElementById("input--evollis-web-service-creditor");
    const paymentOrderReference = document.getElementById("input--evollis-payment-order-reference");
    const accessKeyRef = document.getElementById("input--evollis-access-key-ref");
    let request = new XMLHttpRequest();
    request.open("POST", webServiceCreditor, true);
    request.onreadystatechange = callbackGetPaylineToken.bind(this, request, authenticationToken);
    const DateExpiration = document.getElementById("input--evollis-expiration-month").value.concat(document.getElementById("input--evollis-expiration-year").value.substring(2, 4));
    data = "data=" + paymentOrderReference
        + "&accessKeyRef=" + accessKeyRef
        + "&cardNumber=" + document.getElementById("input--evollis-card-number").value
        + "&cardExpirationDate=" + DateExpiration
        + "&cardCvx=" + document.getElementById("input--evollis-cvx").value;
    request.send(data);
}**/

const getPaylineToken = (paymentInformation, authenticationToken) => {
  let request = new XMLHttpRequest();
  request.open("POST", paymentInformation.webServiceCreditor, true);
  request.onreadystatechange = callbackGetPaylineToken.bind(this, request, authenticationToken);
  const DateExpiration = document
    .getElementById("input--evollis-expiration-month")
    .value.concat(document.getElementById("input--evollis-expiration-year").value.substring(2, 4));
  const data =
    "data=" +
    paymentInformation.paymentOrderReference +
    "&accessKeyRef=" +
    paymentInformation.web2Token +
    "&cardNumber=" +
    document.getElementById("input--evollis-card-number").value +
    "&cardExpirationDate=" +
    DateExpiration +
    "&cardCvx=" +
    document.getElementById("input--evollis-cvx").value;
  request.send(data);
};

const callbackGetPaylineToken = (request, authenticationToken) => {
  if (request.readyState === XMLHttpRequest.DONE) {
    if (request.status === 200) {
      const response = request.responseText;
      if (response.includes("data=")) {
        check3DSecure(request.responseText, authenticationToken);
      } else {
        console.log("Payline error : " + response.replace("errorCode=", ""));
        if (!authenticationToken) {
          document.getElementById("loader--evollis").style.display = "none";
        }
      }
    } else {
      formatAndShowError(request);
      document.getElementById("loader--evollis").style.display = "none";
    }
  }
};

const check3DSecure = (paylineToken, authenticationToken) => {
  let request = new XMLHttpRequest();
  request.open("POST", evollisServer + "check-3d-secure/" + opl, true);
  request.onreadystatechange = callbackCheck3DSecure.bind(this, request, authenticationToken);
  if (authenticationToken) {
    request.setRequestHeader("Authorization", authenticationToken);
  } else {
    request.setRequestHeader("Authorization", "Bearer " + document.getElementById("evollisToken").value);
  }
  request.setRequestHeader("Content-Type", "application/json");
  const data = {
    pspToken: paylineToken,
    returnUrl: returnUrl
  };
  request.send(JSON.stringify(data));
};

const callbackCheck3DSecure = (request, authenticationToken) => {
  if (request.readyState === XMLHttpRequest.DONE) {
    if (request.status === 200) {
      if (request.responseText) {
        let check3DSecureResponse = JSON.parse(request.responseText);
        check3DSecureResponse.termUrlValue = evollisServer + "authorization-3d-secure/" + opl;
        goToAcsCheck(check3DSecureResponse);
      } else {
        doPaymentAuthorization(authenticationToken);
      }
    } else {
      formatAndShowError(request);
      document.getElementById("loader--evollis").style.display = "none";
    }
  }
};

const goToAcsCheck = check3DSecureResponse => {
  let div = document.getElementById("acs-evollis-widget");
  let form = document.createElement("form");
  form.setAttribute("method", "POST");
  form.setAttribute("style", "display:none");
  form.setAttribute("action", check3DSecureResponse.actionUrl);
  form.innerHTML = [
    "<input type='hidden' name=" +
      check3DSecureResponse.termUrlName +
      " value=" +
      check3DSecureResponse.termUrlValue +
      "/>",
    "<input type='hidden' name=" +
      check3DSecureResponse.paReqName +
      " value=" +
      check3DSecureResponse.paReqValue +
      "/>",
    "<input type='hidden' name=" + check3DSecureResponse.mdName + " value=" + check3DSecureResponse.mdValue + "/>"
  ].join("<br/>");
  div.appendChild(form);
  form.submit();
};

const doPaymentAuthorization = authenticationToken => {
  let request = new XMLHttpRequest();
  request.open("POST", evollisServer + "authorization/" + opl, true);
  request.setRequestHeader("Content-Type", "application/json");
  request.onreadystatechange = callbackDoPaymentAuthorization.bind(this, request, authenticationToken);
  if (authenticationToken) {
    request.setRequestHeader("Authorization", authenticationToken);
  } else {
    request.setRequestHeader("Authorization", "Bearer " + document.getElementById("evollisToken").value);
  }
  request.send();
};

const callbackDoPaymentAuthorization = (request, authenticationToken) => {
  if (request.readyState === XMLHttpRequest.DONE) {
    if (request.status === 200) {
      const paylineResponse = JSON.parse(request.responseText);
      window.location = returnUrl + "?code=" + paylineResponse.code;
    } else {
      if (!authenticationToken) {
        document.getElementById("loader--evollis").style.display = "none";
      }
      formatAndShowError(request);
    }
  }
};

const formatAndShowError = request => {
  console.log("Error " + request.status + " : " + request.responseText);
};
