import React, { Component } from "react";
import { Field as FinalFormField } from "react-final-form";
import { Alert, Form, FormGroup, Col } from "react-bootstrap";
import { AccessKeysContext } from "../context/AccessKeysContext";
export default class TextField extends Component {

  render() {
    return (
      <AccessKeysContext.Consumer>
        {(accessKeys = {}) => {
          const { name } = this.props;
          const ak = accessKeys[name] || {};
          const {
            hidden,
            placeholder,
            label,
            className,
            xs,
            lg,
            xl,
            md,
            sm,
            xsHidden,
            smHidden,
            mdHidden,
            lgHidden,
            xlHidden,
            disabled,
            hasServerErrors,
            noFormGroup,
            initialValue,
            type = "text",
            trimOnBlur,
            spaceEvery4Char
          } = { ...this.props, ...ak };

          if (hidden) {
            return false;
          }
          let trimOnBlurProps = null;
          if (trimOnBlur === true) {
            trimOnBlurProps = {
              formatOnBlur:  true,
              format: this.trimValue
            };
          }
          let spaceEvery4CharProps = null;
          if (spaceEvery4Char === true) {
            spaceEvery4CharProps = {
              onKeyUp: this.formatSpaceEvery4Char,
              onBlur: this.formatSpaceEvery4Char
            };
          }
          return (
            <FinalFormField
              name={name}
              component="input"
              type={type}
              placeholder={placeholder}
              className="form-control"
              initialValue={initialValue}
              {...trimOnBlurProps}
            >
              {({ input, meta }) => {
                const metaError = meta.error && (meta.touched || hasServerErrors);
                const hasError = metaError || meta.submitError;
                const cl = {
                  "d-none": xsHidden || smHidden || mdHidden || lgHidden || xlHidden,
                  "d-sm-block": !smHidden,
                  "d-sm-none": smHidden,
                  "d-md-block": !mdHidden,
                  "d-md-none": mdHidden,
                  "d-lg-block": !lgHidden,
                  "d-lg-none": lgHidden,
                  "d-xl-block": !xlHidden,
                  "d-xl-none": xlHidden,
                  "col-no-padding": true
                };
                if (className) {
                  cl[className] = true;
                }
                const hasColParam =
                  xs || lg || xl || md || sm || xsHidden || smHidden || mdHidden || lgHidden || xlHidden;
                const colParams = { className: cl, xs, lg, xl, md, sm };

                let body = [
                  <React.Fragment key="label">{label && <Form.Label>{label}</Form.Label>}</React.Fragment>,
                  <Form.Control
                    key="input"
                    {...input}
                    className={hasError && "textfield-error"}
                    type="text"
                    placeholder={placeholder}
                    disabled={disabled}
                    {...spaceEvery4CharProps}
                  />,
                  <React.Fragment key="alert">
                    {hasError && <p className="required-message">{meta.error || meta.submitError}</p>}
                  </React.Fragment>
                ];

                if (!noFormGroup) {
                  body = <FormGroup>{body}</FormGroup>;
                }

                if (hasColParam) {
                  body = <Col {...colParams}>{body}</Col>;
                }

                return body;
              }}
            </FinalFormField>
          );
        }}
      </AccessKeysContext.Consumer>
    );
  }

  formatSpaceEvery4Char(event) {
    let formattedChain = event.currentTarget.value?.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
    event.currentTarget.value = formattedChain;
  }

  trimValue(value) {
    return value ? value.trim() : null;
  }
}
