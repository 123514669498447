import React, { Component } from "react";
import { Switch } from "react-router-dom";

import "../asset/sass/uzit/uzit.scss";
import BackContractOKPage from "./BackContractOKPage";
import BackScoreOKPage from "./BackScoreOKPage";
import CbPage from "./CbPage/CbPage";
import Client1Page from "./Client1Page";
import Client2Page from "./Client2Page";
import Client3Page from "./Client3Page/Client3Page";
import SummaryPage from "./SummaryPage";
import FailPage from "./FailPage";
import MaintenancePage from "./MaintenancePage";
import FinalPage from "./FinalPage";
import NetheosPage from "./NetheosPage";
import ComeBackLaterPage from "./ComeBackLaterPage";
import PaymentKOPage from "./PaymentKOPage";
import ScoreKOPage from "./ScoreKOPage";
import RouteWithAccessKeys from "./RouteWithAccessKeys";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
}

class UzitPagesLayout extends Component {
  render() {
    return (
      <>
        <Switch>
          <ScrollToTop>
            <RouteWithAccessKeys
              path="/uzit/cb"
              render={routeProps => <CbPage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
              path="/uzit/paymentko"
              render={routeProps => (
                <PaymentKOPage {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/fail"
              render={routeProps => <FailPage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
              path="/uzit/maintenance"
              render={routeProps => <MaintenancePage {...routeProps} {...this.props} />}
            />
            <RouteWithAccessKeys
              path="/uzit/client1"
              render={routeProps => (
                <Client1Page {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/client2"
              render={routeProps => (
                <Client2Page {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/client3"
              render={routeProps => (
                <Client3Page {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/summary"
              render={routeProps => (
                <SummaryPage {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/score-ko"
              render={routeProps => (
                <ScoreKOPage {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/back-score-ok"
              render={routeProps => (
                <BackScoreOKPage {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/netheos"
              render={routeProps => (
                <NetheosPage {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/signature-paused"
              render={routeProps => (
                <ComeBackLaterPage {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/back-contract-ok"
              render={routeProps => (
                <BackContractOKPage {...routeProps} {...this.props} />
              )}
            />
            <RouteWithAccessKeys
              path="/uzit/final"
              render={routeProps => <FinalPage {...routeProps} {...this.props} />}
            />
          </ScrollToTop>
        </Switch>
      </>
    );
  }
}

export default UzitPagesLayout;
