import React, { Component } from "react";

class Bank extends Component {
  state = {};

  componentDidMount() {}

  render() {
    let contract = this.props.contract;
    let quotation = contract.quotations[0];

    return (
      <div className="modal-financing">
        <div>
          <span className="payment-schedule-total">{quotation.simulation.formattedCurrency}</span>
          <h5>My purchase</h5>
          <p>Date: {quotation.simulation.formattedDate}</p>
        </div>
        <br />
        <h5>My bank</h5>
        <p>Select the bank where you receive your income</p>
        <img
          className="bank-button"
          src={`/images/bank-logos.png`}
          alt=""
          onClick={() => this.props.onSetStep("SignSepaMandate")}
        />
        <div className="text-right">
          <button type="button" className="btn-simple" onClick={() => this.props.onSetStep(-1)}>
            back
          </button>
        </div>
      </div>
    );
  }
}

export default Bank;
